import { TBadge, TOfficeBadge } from "../lib/types/badge.type";
import axios from "./axios";

export const api_myBadgeList = async () => {
  const res = await axios.get("/office-badge/mine");
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {
    list: TOfficeBadge[];
    availableBadge: TBadge | null;
    postCount: number;
    cannotApplyMsg?: string;
  };
};
export const api_badgeList = async () => {
  const res = await axios.get("/office-badge/available");
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: (TBadge & { available: boolean })[] };
};

export const api_badgePurchase = async (badgeId: string, apartId: string) => {
  const res = await axios.post("/office-badge", { badgeId, apartId });
  if (!res) return;

  return res;
};

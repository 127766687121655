import { useCallback } from "react";
import ModalBase from "./Base";
import { useRecoilState } from "recoil";
import { atom__closePicture } from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";

const ImageModal = () => {
  const [r__closePicture, r__setatomClosePicture] = useRecoilState(
    atom__closePicture
  ) as any;

  const close = useCallback(() => {
    r__setatomClosePicture({});
  }, [r__setatomClosePicture]);

  if (!r__closePicture?.show) {
    return <></>;
  }

  return (
    <ModalBase visible={r__closePicture.show} noInner onClickOuter={close}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        HELLO
        <img
          src={r__closePicture.image}
          alt="클릭한 이미지"
          style={{ maxWidth: 580, maxHeight: 580 }}
        />
      </div>
    </ModalBase>
  );
};

export default ImageModal;

import { useEffect, useState } from "react";
import { TNotice } from "../../lib/types/notice.type";
import { api_noticeDetail } from "../../api/notice";
import { printFormattedDate } from "../../lib/util";
import { useParams } from "react-router-dom";
import useIsMobile from "../../lib/hooks/useIsMobile";
import { useSetRecoilState } from "recoil";
import { atom__me } from "../../lib/recoil/common.atom";

const NoticeDetailPage = () => {
  const isMobile = useIsMobile();
  const params = useParams();

  const r__setMe = useSetRecoilState(atom__me);

  const [detail, setDetail] = useState<TNotice | null>(null);

  useEffect(() => {
    if (!params.noticeId) return;
    api_noticeDetail(params.noticeId).then((res) => {
      if (!res) return;
      setDetail(res.notice);

      // 가장 최근 등록된 공지사항을 조회했다면 unreadNotification 변경
      if (res.isLastNotice) {
        r__setMe((prev) => {
          if (!prev) return null;
          return { ...prev, unreadNotification: false };
        });
      }
    });
  }, []);

  if (!detail) return null;

  return (
    <div className="news-page-wrapper">
      <div
        className={`news-page-inner ${
          isMobile ? "text-small" : "text-medium"
        } bold`}
      >
        <div
          className="news-page-header"
          style={isMobile ? { height: "100px" } : {}}
        >
          {!isMobile && <h2>공지사항</h2>}
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p>꿀단지의 서비스 안내와 공지에 대한 소식을 전해드립니다.</p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <p
              style={{ width: "200px", height: "40px", textAlign: "center" }}
            ></p>
          </div>
        </div>

        <div
          className="news-row"
          style={{
            justifyContent: "space-between",
          }}
        >
          <p style={{ marginLeft: "10px" }}>{detail.subject}</p>
          <p style={{ width: "200px", textAlign: "center" }}>
            {printFormattedDate(new Date(detail.createdAt), { seperator: "." })}
          </p>
        </div>

        <pre style={{ padding: "10px", width: "100%" }}>{detail.content}</pre>
      </div>
    </div>
  );
};

export default NoticeDetailPage;

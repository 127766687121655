import useIsMobile from "../../lib/hooks/useIsMobile";
import { TBadge } from "../../lib/types/badge.type";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import iconMedalSilver from "../../assets/medal_silver.png";
import { printFormattedDate } from "../../lib/util";

interface IProps {
  id: string;
  badge: TBadge;
  startDate: string | null;
  endDate: string | null;
  apartName: string;
  isPending: boolean;

  style?: React.CSSProperties;
}
const MyBadge = (props: IProps) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: "100%",
        padding: isMobile ? "20px 40px" : "30px 30px 40px",
        border: "1px solid var(--yellow-dark)",
        borderRadius: "8px",
        overflow: "hidden",
        position: "relative",
        ...(props.style ?? {}),
      }}
    >
      {isMobile ? (
        <div className="flex-col-center-center">
          <div
            className="flex-row-between-center"
            style={{ marginBottom: "28px" }}
          >
            <img
              src={
                props.badge.worth === 50
                  ? iconMedalGold
                  : props.badge.worth === 30
                  ? iconMedalSilver
                  : iconMedalBronze
              }
              style={{ width: "32px", height: "32px", marginRight: "10px" }}
            />
            <p className="text-medium bold">{props.badge.name}</p>
          </div>

          <div
            className="flex-row-between-center"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <p className="text-small bold">지정 단지</p>
            <p className="text-small bold">{props.apartName}</p>
          </div>

          <div
            className="flex-row-between-center"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <p className="text-small bold">시작일</p>
            <p className="text-medium bold">
              {props.startDate
                ? printFormattedDate(new Date(props.startDate))
                : "-"}
            </p>
          </div>

          <div className="flex-row-between-center" style={{ width: "100%" }}>
            <p className="text-small bold">종료일</p>
            <p className="text-medium bold">
              {props.endDate
                ? printFormattedDate(new Date(props.endDate))
                : "-"}
            </p>
          </div>
        </div>
      ) : (
        <>
          {/* pc: 윗줄 */}
          <div
            className="flex-row-between-center"
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <div className="flex-row-between-center">
              <img
                src={
                  props.badge.worth === 50
                    ? iconMedalGold
                    : props.badge.worth === 30
                    ? iconMedalSilver
                    : iconMedalBronze
                }
                style={{ width: "32px", height: "32px", marginRight: "10px" }}
              />
              <p className="text-medium bold">{props.badge.name}</p>
            </div>
            <div className="flex-row-between-center" style={{ width: "240px" }}>
              <p className="text-small bold" style={{ width: "100px" }}>
                시작일
              </p>
              <p className="text-medium bold">
                {props.startDate
                  ? printFormattedDate(new Date(props.startDate))
                  : "-"}
              </p>
            </div>
          </div>

          {/* pc: 아랫줄 */}
          <div className="flex-row-between-center">
            <div className="flex-row-start-center">
              <p className="text-small bold" style={{ marginRight: "24px" }}>
                지정 단지
              </p>
              <p className="text-small bold">{props.apartName}</p>
            </div>
            <div className="flex-row-between-center" style={{ width: "240px" }}>
              <p className="text-small bold" style={{ width: "100px" }}>
                종료일
              </p>
              <p className="text-medium bold">
                {props.endDate
                  ? printFormattedDate(new Date(props.endDate))
                  : "-"}
              </p>
            </div>
          </div>
        </>
      )}

      {props.isPending && (
        <div
          className="flex-row-center-center"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#00000044",
          }}
        >
          <h1 style={{ color: "#fff", textShadow: "2px 3px 8px #444" }}>
            처리중
          </h1>
        </div>
      )}
    </div>
  );
};

export default MyBadge;

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom__complaintModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import ModalBase from "./Base";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import Select from "../Select";
import { api_complaint } from "../../api/user";
import { atom__me } from "../../lib/recoil/common.atom";

const ModalComplaint = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__complaintModalInfo
  );
  const r__me = useRecoilValue(atom__me);

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [reason, setReason] = useState("none");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (!r__modalInfo) {
      setReason("none");
      setContent("");
      return;
    }
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const complaint = async () => {
    if (!r__modalInfo) return;
    if (r__me?.isSale) {
      alert("영업직원 계정은 제한되는 기능입니다.");
      return;
    }

    const res = await api_complaint({
      targetUserId: r__modalInfo.userId,
      reason,
      content,
    });
    if (!res) return;

    close();
    r__setSuccessModalInfo({
      title: "신고접수 완료",
      desc: [
        "정상적으로 신고가 접수되었습니다.",
        "관리자 확인 후 경고 등의 조치가 진행될 예정입니다.",
        "감사합니다.",
      ],
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>신고하기</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label className="text-small">신고 유형</label>
      <Select
        items={[
          { label: "유형 선택", value: "none" },
          { label: "공동중개거부", value: "공동중개거부" },
          { label: "허위매물등록", value: "허위매물등록" },
        ]}
        value={reason}
        onChange={(v) => setReason(v)}
        style={{ marginBottom: "10px" }}
      />
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        style={{ marginBottom: "30px", height: "200px" }}
        placeholder="상세한 신고 사유 작성. ex) A동 a호 매물을 허위로 올렸습니다."
      ></textarea>

      <BaseButton
        text="신고"
        onClick={complaint}
        disabled={content.length < 10 || reason === "none"}
      />
    </ModalBase>
  );
};

export default ModalComplaint;

import { Link, useLocation, useNavigate } from "react-router-dom";
import iconLogo from "../../../assets/logo.png";
import iconProfileDefault from "../../../assets/profile-default.png";
import iconTalk from "../../../assets/talk.png";
import iconNotiNew from "../../../assets/noti-new.png";
import iconNoti from "../../../assets/noti.png";
import GlobalPostFilter from "../GlobalPostFilter";
import SubBar from "./SubBar";
import { GNB_MENU } from "./menu";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atom__me } from "../../../lib/recoil/common.atom";
import {
  atom__loginModalInfo,
  atom__notificationModalInfo,
} from "../../../lib/recoil/modal.atom";
import ModalNotification from "../../Modal/Notification";
import { KAKAO_LINK } from "../../../lib/util";

const GlobalNavigationBarPc = () => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  const r__me = useRecoilValue(atom__me);
  const r__setLoginModalInfo = useSetRecoilState(atom__loginModalInfo);
  const r__setNotiModalInfo = useSetRecoilState(atom__notificationModalInfo);

  return (
    <section className="gnb-wrapper">
      <div className="gnb">
        <img
          src={iconLogo}
          className="logo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (pathName === "/map") return;
            navigate("/map");
          }}
        />
        <ul className="menu-container">
          {GNB_MENU.map((menu, i) => {
            // 로그인한 계정 종류에 따라 메뉴 표시여부 결정
            if (menu.authorizedLevel > (r__me?.level || 0)) return null;
            if (menu.needMembership && r__me?.activeMembershipId === null)
              return null;

            // 대메뉴 클릭 시 이동시킬 소메뉴 경로
            let subMenuPath = "";
            // authorizedLevel을 만족하는 소메뉴들
            const filteredByLevel = menu.items?.filter(
              (subMenu) => (subMenu.authorizedLevel ?? 0) <= (r__me?.level ?? 0)
            );
            if (!filteredByLevel.length) subMenuPath = menu.path;
            // 멤버십이 있다면 filteredByLevel의 첫번쩨를 채택
            else if (!!r__me?.activeMembershipId)
              subMenuPath = filteredByLevel[0].path;
            // 멤버십이 만료되어서 없다면 needMembership=false인걸 채택
            else {
              subMenuPath =
                filteredByLevel.find((subMenu) => !subMenu.needMembership)
                  ?.path ?? menu.path;
            }

            return (
              <li key={i}>
                <Link
                  to={subMenuPath}
                  className={pathName.startsWith(menu.path) ? "current" : ""}
                >
                  {menu.category}
                </Link>
              </li>
            );
          })}
        </ul>

        <div className="flex-row-center-center">
          <div>
            <span
              style={{
                fontSize: "28px",
                fontWeight: 700,
                lineHeight: "33.41px",
                color: "var(--brown)",
              }}
            >
              고객센터 : 1800-5664
            </span>
            <div
              className="flex-row-center-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(KAKAO_LINK, "_blank");
              }}
            >
              <img src={iconTalk} style={{ width: "36px", height: "36px" }} />
              <span
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  lineHeight: "33.41px",
                  color: "var(--beige)",
                }}
              >
                카카오톡 플러스채널
              </span>
            </div>
          </div>

          <div
            className="flex-row-center-center"
            style={{ height: "100%", margin: "0 20px", cursor: "pointer" }}
            onClick={() => {
              if (!r__me) return;
              if (!r__me.activeMembershipId) return;
              r__setNotiModalInfo((prev) => (prev ? null : true));
            }}
          >
            <img
              src={r__me?.unreadNotification ? iconNotiNew : iconNoti}
              style={{ width: "28px", height: "32px" }}
            />
          </div>
          <div
            className="flex-row-center-center"
            style={{ height: "100%", cursor: "pointer" }}
            onClick={() => {
              // 로그인 전이면 로그인모달 표시, 로그인 후면 내정보 페이지로 이동
              if (!r__me) {
                r__setLoginModalInfo({});
                return;
              }

              if (pathName === "/my/profile") return;

              navigate("/my/profile");
            }}
          >
            <img
              src={r__me?.profileImgUrl || iconProfileDefault}
              style={{ width: "60px", height: "60px", borderRadius: "60px" }}
            />
          </div>
        </div>
      </div>

      <SubBar />

      {pathName !== "/map" && <ModalNotification />}
    </section>
  );
};

export default GlobalNavigationBarPc;

/* eslint-disable jsx-a11y/alt-text */
import { TPost } from "../../lib/types/post.type";
import postDefaultImage from "../../assets/post-default-480_260.png";
import iconArrowDouble from "../../assets/arrow-right-double.png";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilver from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import iconArrowLeft from "../../assets/arrow-left-background.png";
import iconArrowRight from "../../assets/arrow-right-background.png";
import iconHeartFill from "../../assets/heart-fill.png";
import iconHeartEmpty from "../../assets/heart-empty.png";
import iconNote from "../../assets/note.png";
import iconCloseWhite from "../../assets/cancel_white.png";
import { useEffect, useState } from "react";
import {
  convertToPyeong,
  printCost,
  printFormattedDateKr,
} from "../../lib/util";
import { USER_LEVEL_TO_LABEL } from "../../lib/types/user.type";
import { useSetRecoilState } from "recoil";
import {
  atom__closePicture,
  atom__confirmModalInfo,
  atom__postMemoModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { api_postWish } from "../../api/post";
import PostInfoRow from "./PostInfoRow";

interface IProps {
  post: TPost;
  wished: boolean | null;
  withDetail?: boolean;
  noBorderRadius?: boolean;
  style?: React.CSSProperties;
  onClose?: () => void;
  onDeleteWish?: (postId: string) => void;
  onClickCreator?: any;
}
const PostDetail = ({
  post,
  wished,
  withDetail,
  noBorderRadius,
  style,
  onClose,
  onDeleteWish,
  onClickCreator,
}: IProps) => {
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setPostMemoModalInfo = useSetRecoilState(atom__postMemoModalInfo);
  const r__setatomClosePicture = useSetRecoilState(atom__closePicture);

  const [headlineCost, setHeadlineCost] = useState("");
  const [creator, setCreator] = useState<{
    type: any;
    name: string;
    id?: string;
    level?: number;
    postId?: string | number;
  }>({ type: "user", id: "", name: "" });
  const [badgeImg, setBadgeImg] = useState<string | null>(null);
  const [currentImgIdx, setCurrentImgIdx] = useState(0);
  const [isWish, setIsWish] = useState(wished);

  useEffect(() => {
    if (wished === null) return;

    setIsWish(!!wished);
  }, [wished]);

  useEffect(() => {
    if (!post) {
      setHeadlineCost("");
      setBadgeImg(null);
      return;
    }

    // 헤드라인 (ex: 매매 7억 1,000)
    let hCost = post.saleKind + " ";
    hCost += printCost(post.costOnce);
    if (post.costMonth)
      hCost += " / " + printCost(post.costMonth, { manwonLabel: "만원" });
    setHeadlineCost(hCost);

    console.log(post);
    // 표시할 등록자
    // 소유주인 경우
    if (post.ownerName) {
      setCreator({ type: "owner", name: post.ownerName, postId: post.id });
    } else if (post.creatorUser) {
      // 중개보조원이 올린 경우: 사무소
      if (post.creatorUser.level === 40) {
        setCreator({
          type: "office",
          id: post.officeId as string,
          name: post.office.name,
          postId: post.id,
        });
      } else
        setCreator({
          type: "user",
          id: post.creatorUserId as string,
          name: post.creatorUser.name,
          level: post.creatorUser.level,
          postId: post.id,
        });
    } else if (post.office)
      setCreator({
        type: "office-2",
        name: post.office.name,
        id: post.officeId as string,
        postId: post.id,
      });

    // 사무소 배지
    if (post.badgeWorth === 0) {
      setBadgeImg(null);
      return;
    }
    setBadgeImg(
      post.badgeWorth === 50
        ? iconMedalGold
        : post.badgeWorth === 30
        ? iconMedalSilver
        : iconMedalBronze
    );
  }, [post]);

  const wish = () => {
    // 삭제할땐 컨펌 한번 하고 삭제.
    if (isWish) {
      r__setConfirmModalInfo({
        title: "찜 삭제",
        desc: [
          "찜 삭제 시, 매물에 작성한 메모가 함께 삭제됩니다.",
          "정말 삭제하시겠습니까?",
        ],
        btnRightText: "삭제",
        onClickBtnRight: () => {
          api_postWish(post.id, false).then((res) => {
            if (!res) return;
            setIsWish(res.wished);

            r__setConfirmModalInfo(null);
            r__setSuccessModalInfo({ desc: ["찜 삭제되었습니다."] });
            onDeleteWish?.(post.id);
          });
        },
      });

      return;
    }

    api_postWish(post.id, true).then((res) => {
      if (!res) return;
      setIsWish(res.wished);
    });
  };

  return (
    <div
      style={{
        borderRadius: noBorderRadius ? 0 : "20px",
        boxShadow: "0px 4px 4px 0px #00000040",
        ...(style ?? {}),
      }}
    >
      <div
        onClick={(event: any) => {
          if (event.target.nodeName === "DIV") {
            r__setatomClosePicture({
              show: true,
              image: post.images[currentImgIdx]?.url480W ?? postDefaultImage,
            });
          }
        }}
        style={{
          width: "100%",
          aspectRatio: 480 / 260,
          position: "relative",
          borderTopLeftRadius: noBorderRadius ? 0 : "20px",
          borderTopRightRadius: noBorderRadius ? 0 : "20px",
          overflow: "hidden",
          cursor: "pointer",
        }}
      >
        {/* 진짜 사진 */}
        <img
          src={post.images[currentImgIdx]?.url480W ?? postDefaultImage}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "relative",
          }}
        />
        {/* dim(어둡게) 처리할 레이어 */}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "#00000033",
            top: 0,
          }}
        />
        {/* 사진 n/m 표시 */}
        {post.images.length > 0 && (
          <span
            style={{
              position: "absolute",
              bottom: "8px",
              right: "8px",
              fontSize: "10px",
              fontWeight: "700",
              color: "#fff",
              textShadow: "2px 3px 4px #555",
            }}
          >
            {currentImgIdx + 1} / {post.images.length}
          </span>
        )}
        {/* 이전사진/다음사진 버튼 */}
        {post.images.length >= 2 && (
          <div
            className="flex-row-between-center"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              padding: "0 8px",
            }}
          >
            <img
              src={iconArrowLeft}
              style={{
                width: "36px",
                height: "36px",
                cursor: "pointer",
              }}
              onClick={() =>
                setCurrentImgIdx((prev) => {
                  if (prev === 0) return post.images.length - 1;
                  return prev - 1;
                })
              }
            />
            <img
              src={iconArrowRight}
              style={{
                width: "36px",
                height: "36px",
                cursor: "pointer",
              }}
              onClick={() =>
                setCurrentImgIdx((prev) => {
                  if (prev === post.images.length - 1) return 0;
                  return prev + 1;
                })
              }
            />
          </div>
        )}

        {/* 찜  */}
        {isWish !== null && (
          <span
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              padding: "4px",
              cursor: "pointer",
            }}
            onClick={wish}
          >
            <img
              src={isWish ? iconHeartFill : iconHeartEmpty}
              style={{ width: "28px", height: "28px" }}
            />
          </span>
        )}

        {/* 닫기 버튼 */}
        {!!onClose && (
          <span
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              padding: "4px",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            <img
              src={iconCloseWhite}
              style={{ width: "28px", height: "28px" }}
            />
          </span>
        )}
      </div>

      <div style={{ padding: "20px" }}>
        {/* 가격, 아파트명 */}
        <div
          className="flex-row-between-center"
          style={{ marginBottom: "10px" }}
        >
          <h2>{headlineCost}</h2>
          <p className="text-small" style={{ color: "var(--border-gray)" }}>
            {post.apart.kaptName}
          </p>
        </div>

        {/* 타입, 방/욕실수, 동, 층, 메모 */}
        <div
          className="flex-row-between-center"
          style={{ marginBottom: "36px", textAlign: "center" }}
        >
          <div className="flex-row-start-center" style={{ flex: 1 }}>
            <p className="text-small" style={{ width: "70px" }}>
              {post.areaType}
            </p>
            <span
              style={{
                width: "2px",
                height: "30px",
                backgroundColor: "var(--border-gray)",
                margin: "0 3px",
              }}
            />
            <p className="text-small" style={{ width: "78px" }}>
              방{post.cntRoom} / 욕실{post.cntBath}
            </p>
            <span
              style={{
                width: "2px",
                height: "30px",
                backgroundColor: "var(--border-gray)",
                margin: "0 3px",
              }}
            />
            <p className="text-small" style={{ width: "70px" }}>
              {post.dong}동
            </p>
            <span
              style={{
                width: "2px",
                height: "30px",
                backgroundColor: "var(--border-gray)",
                margin: "0 3px",
              }}
            />
            <p className="text-small" style={{ width: "78px" }}>
              {post.floor}층/{post.apart.kaptTopFloor}층
            </p>
          </div>
          {/* 메모 */}
          {isWish === true ? (
            <span
              style={{
                padding: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                r__setPostMemoModalInfo({
                  postId: post.id,
                });
              }}
            >
              <img src={iconNote} style={{ width: "24px", height: "24px" }} />
            </span>
          ) : (
            <span style={{ width: "1px" }} />
          )}
        </div>

        {/* 중개사이름 및 배지 */}
        <div
          className="flex-row-center-center"
          style={{
            marginBottom: "36px",
            cursor:
              !!onClickCreator && creator.type === "user" && !!creator.id
                ? "pointer"
                : "default",
          }}
          onClick={() => {
            console.log(creator);

            const userId = creator?.id ? creator.id : creator.name;

            onClickCreator?.({
              userId: userId,
              postId: creator.postId,
              type: creator.type,
            });
          }}
        >
          <img
            src={iconArrowDouble}
            style={{ width: "24px", height: "24px" }}
          />

          {badgeImg !== null && (
            <img src={badgeImg} style={{ width: "36px", height: "32px" }} />
          )}

          <p className="username" style={{ marginLeft: "14px" }}>
            {creator.name}
            {!!creator.level && ` ${USER_LEVEL_TO_LABEL[creator.level]}`}
          </p>
        </div>

        {/* 소개글 */}
        <p
          className="label-small"
          style={{ textAlign: "left", marginBottom: "6px" }}
        >
          {post.introduceTitle}
        </p>
        <p
          className="label-small"
          style={{
            textAlign: "left",
            color: "var(--border-gray)",
            marginBottom: "36px",
          }}
        >
          {post.introduceContent}
        </p>

        {withDetail && (
          <>
            {/* 기타 정보 */}
            <p
              className="label-small"
              style={{ textAlign: "left", marginBottom: "6px" }}
            >
              아파트정보
            </p>

            <PostInfoRow label="타입" info={post.areaType} />
            <PostInfoRow
              label="면적"
              info={`전용 ${post.exclusiveArea}m² (${convertToPyeong(
                post.exclusiveArea
              )}평) / 공용 ${post.commonArea}m² (${convertToPyeong(
                post.commonArea
              )}평)`}
            />
            <PostInfoRow
              label="평균 관리비"
              info={printCost(post.manageFeeAvg, { manwonLabel: "만원" })}
            />
            <PostInfoRow label="관리비 항목" info={post.manageFeeName} />
            <PostInfoRow
              label="방 수 / 욕실 수"
              info={`${post.cntRoom}개 / ${post.cntBath}개`}
            />
            <PostInfoRow label="방향" info={post.window} />
            <PostInfoRow
              label="입주가능일"
              info={
                post.enterableDate
                  ? `협의 (${printFormattedDateKr(
                      new Date(post.enterableDate)
                    )}부터)`
                  : "협의"
              }
            />
            <PostInfoRow label="융자" info={post.mortgage} />
            <PostInfoRow
              label="보증보험"
              info={post.canInsurance ? "가능" : "불가능"}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PostDetail;

import React, { useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import RootPage from "./pages/Root";
import Layout from "./components/Layout";
import NotFountPage from "./pages/NotFound";
import NewsGlobalPage from "./pages/News/NewsGlobal";
import NewsRegionPage from "./pages/News/NewsRegion";
import NoticePage from "./pages/News/Notice";
import MapPage from "./pages/Map";
import RegisterStep1Page from "./pages/Register/Step1";
import RegisterStep2Page from "./pages/Register/Step2";
import RegisterCompletePage from "./pages/Register/Complete";
import ProfilePage from "./pages/My/Profile";
import useIsMobile from "./lib/hooks/useIsMobile";
import { useRecoilState, useSetRecoilState } from "recoil";
import { atom__isMobile, atom__me } from "./lib/recoil/common.atom";
import NoticeDetailPage from "./pages/News/NoticeDetail";
import MembershipPage from "./pages/My/Membership";
import PostStartPage from "./pages/Post/Start";
import PostStep0Page from "./pages/Post/Step0";
import PostStep1Page from "./pages/Post/Step1";
import PostStep2Page from "./pages/Post/Step2";
import PostStep3Page from "./pages/Post/Step3";
import PostStep4Page from "./pages/Post/Step4";
import BadgePage from "./pages/Office/Badge";
import EmployeePage from "./pages/Office/Employee";
import MyPostsPage from "./pages/Office/MyPosts";
import WishListPage from "./pages/My/WishList";
import axios, { BASE_URL } from "./api/axios";
import {
  atom__alertModalInfo,
  atom__successModalInfo,
} from "./lib/recoil/modal.atom";
import { api_checkDuplicatedLogin } from "./api/auth";
import { io } from "socket.io-client";

export const socket = io(BASE_URL);

function App() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const r__setIsMobile = useSetRecoilState(atom__isMobile);
  const [r__me, r__setMe] = useRecoilState(atom__me);
  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const refIntervalDupLogin = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (socket) {
      socket.on("sendMessageToNearbyUsers", () => {
        r__setAlertModalInfo({
          title: "알림",
          desc: ["근처에 새 매물이 생겼어요"],
        });
      });
    }
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err?.response?.status === 410) {
          r__setMe(null);

          stopCheckDupLogin();

          r__setAlertModalInfo({
            title: "중복로그인 감지",
            desc: [err?.response?.data],
            onClickBtn: () => {
              r__setAlertModalInfo(null);
              navigate("/map");
            },
          });
        } else return err;
      }
    );
  }, []);

  // 중복로그인 검사. 로그인된 상태면 5분마다 검사.
  useEffect(() => {
    if (!r__me) {
      stopCheckDupLogin();
      return;
    }

    refIntervalDupLogin.current = setInterval(
      api_checkDuplicatedLogin,
      1000 * 60 * 5
    ); // 5m
  }, [r__me]);

  useEffect(() => {
    r__setIsMobile(isMobile);
  }, [isMobile]);

  const stopCheckDupLogin = () => {
    if (refIntervalDupLogin.current !== null) {
      clearInterval(refIntervalDupLogin.current);
      refIntervalDupLogin.current = null;
    }
  };

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<RootPage />} />

        {/* 지도페이지 */}
        <Route path="/map" element={<MapPage />} />

        {/* 뉴스/공지사항 */}
        <Route path="/news/global" element={<NewsGlobalPage />} />
        <Route path="/news/region" element={<NewsRegionPage />} />
        <Route path="/news/notice" element={<NoticePage />} />
        <Route path="/news/notice/:noticeId" element={<NoticeDetailPage />} />

        {/* 회원가입 */}
        <Route path="/register/step1" element={<RegisterStep1Page />} />
        <Route path="/register/step2" element={<RegisterStep2Page />} />
        <Route path="/register/complete" element={<RegisterCompletePage />} />

        {/* 중개사무소 - 배지, 인력관리, 등록매물 */}
        <Route path="/office/badge" element={<BadgePage />} />
        <Route path="/office/employee" element={<EmployeePage />} />
        <Route path="/office/post" element={<MyPostsPage />} />

        {/* 매물등록 */}
        <Route path="/post" element={<PostStartPage />} />
        <Route path="/post/step0" element={<PostStep0Page />} />
        <Route path="/post/step1" element={<PostStep1Page />} />
        <Route path="/post/step2" element={<PostStep2Page />} />
        <Route path="/post/step3" element={<PostStep3Page />} />
        <Route path="/post/step4" element={<PostStep4Page />} />

        {/* 마이페이지 - 내정보, 멤버십, 찜한 매물 목록 */}
        <Route path="/my/profile" element={<ProfilePage />} />
        <Route path="/my/membership" element={<MembershipPage />} />
        <Route path="/my/wish" element={<WishListPage />} />
      </Route>

      <Route path="/*" element={<NotFountPage />} />
    </Routes>
  );
}

export default App;

/* eslint-disable jsx-a11y/alt-text */
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import ModalBase from "../Base";
import {
  atom__complaintModalInfo,
  atom__userDetailModalInfo,
} from "../../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import { TUserDetailRes } from "../../../lib/types/user.type";
import { api_userDetail } from "../../../api/user";

import iconClose from "../../../assets/cancel.png";
import iconSiren from "../../../assets/siren.png";
import profileDefault from "../../../assets/profile-default.png";
import PostBoxTop from "../../Post/PostBoxTop";
import BadgeMedal from "../../Badge/BadgeMedal";
import { atom__me } from "../../../lib/recoil/common.atom";

const ModalUserDetailMobile = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__userDetailModalInfo
  );

  const r__me = useRecoilValue(atom__me);
  const r__setComplaintModalInfo = useSetRecoilState(atom__complaintModalInfo);

  const [detail, setDetail] = useState<any | null>(null);

  useEffect(() => {
    if (!r__modalInfo) {
      setDetail(null);
      return;
    }

    api_userDetail(
      r__modalInfo.userId,
      r__modalInfo.postId,
      r__modalInfo.type,
      r__modalInfo.apartId
    ).then((res) => {
      if (!res) return;
      setDetail(res);
    });
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  if (!detail) return null;

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close} noInner>
      <div
        className="flex-col-start-center"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          position: "relative",
          overflowY: "auto",
          padding: "40px 20px 0",
          textAlign: "center",

          border: "1px solid red",
        }}
      >
        <img
          src={detail.user.profileImgUrl || profileDefault}
          style={{
            width: "66px",
            height: "66px",
            borderRadius: "66px",
            border: "1px solid var(--yellow-dark)",
            marginBottom: "10px",
          }}
        />
        {detail.office.badgeWorth > 0 && (
          <BadgeMedal
            worth={detail.office.badgeWorth}
            size={36}
            style={{ marginBottom: "10px" }}
          />
        )}
        <h2 style={{ color: "var(--brown)", marginBottom: "4px" }}>
          {detail.user.name || detail.office.name}
        </h2>
        {detail.office.name ? (
          <p className="text-small bold" style={{ color: "var(--beige)" }}>
            {detail.office.name} (대표자 {detail.office.ceo})
          </p>
        ) : (
          <p className="text-small bold" style={{ color: "var(--beige)" }}>
            소유주 직접등록 매물
          </p>
        )}

        <p className="text-small bold" style={{ color: "var(--border-gray)" }}>
          {detail.office.address}
        </p>
        {detail.user?.type === "owner" ? (
          <img
            style={{ width: 120, marginBottom: 16, marginTop: 16 }}
            src="https://www.honeyaptdanji.com/static/media/logo-full.37f9bc9979526173ee4d.png"
            alt="꿀단지 사진"
          />
        ) : (
          <p
            className="text-small"
            style={{
              color: "var(--border-gray)",
              marginBottom: "20px",
            }}
          >
            중개업등록번호 {detail.office.registerNumber}
          </p>
        )}
        {!!detail.user.phone && (
          <p
            className="text-small bold"
            style={{ color: "var(--brown)", marginBottom: "10px" }}
          >
            {detail.user?.type === "owner" ? "소유주" : "매물담당자"} :{" "}
            {detail.user.phone}
          </p>
        )}

        {!!detail.office.hp && (
          <p
            className="text-small bold"
            style={{ color: "var(--brown)", marginBottom: "40px" }}
          >
            유선(대표번호): {detail.office.hp}
          </p>
        )}

        <p
          className="text-small bold"
          style={{ color: "var(--brown)", marginBottom: "10px" }}
        >
          세입자 : {detail?.user?.customerPhone || "미기재"}
        </p>

        {detail?.user?.gongsil_yn === "Y" && (
          <p
            className="text-small bold"
            style={{ color: "var(--brown)", marginBottom: "40px" }}
          >
            공실
          </p>
        )}

        <div
          className="flex-row-between-center"
          style={{
            width: "100%",
            padding: "20px 0",
          }}
        >
          <p className="text-small bold">등록된 매물</p>
          <h2>{detail.posts.length}건</h2>
        </div>
        {detail.posts.length > 0 ? (
          detail.posts.map((post) => {
            return (
              <div
                style={{
                  width: "100%",
                  padding: "20px",
                  borderBottom: "1px solid #e5e5e5",
                }}
              >
                <PostBoxTop post={post} style={{ width: "100%" }} />
              </div>
            );
          })
        ) : (
          <div
            style={{
              width: "100%",
              padding: "20px",
              borderBottom: "1px solid #e5e5e5",
            }}
          >
            <p className="text-small bold">현재 등록된 매물이 없습니다</p>
          </div>
        )}

        {/* 신고버튼 */}
        {!!r__me && r__me?.id !== r__modalInfo?.userId && (
          <span
            style={{
              position: "absolute",
              padding: "4px",
              top: "10px",
              left: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!r__modalInfo) return;
              r__setComplaintModalInfo({ userId: r__modalInfo.userId });
            }}
          >
            <img src={iconSiren} style={{ width: "28px", height: "28px" }} />
          </span>
        )}

        {/* 닫기버튼 */}
        <span
          style={{
            position: "absolute",
            padding: "4px",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={close}
        >
          <img src={iconClose} style={{ width: "28px", height: "28px" }} />
        </span>
      </div>
    </ModalBase>
  );
};

export default ModalUserDetailMobile;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useRef, useState } from "react";
import iconChangeCircle from "../assets/change_circle.png";

interface IProps {
  onChange: (file?: File) => void;
  style?: React.CSSProperties;
}
const FileInput = (props: IProps) => {
  const refFileInput = useRef<HTMLInputElement>(null);
  const [selectedFilename, setSelectedFilename] = useState("");

  const placeholder = useMemo(() => "파일 선택", []);

  return (
    <div
      className="flex-row-center-center file-input-container"
      onClick={() => {
        refFileInput.current?.click();
      }}
      style={props.style}
    >
      <input
        type="file"
        ref={refFileInput}
        accept="image/png, image/jpg, image/jpeg"
        onChange={(e) => {
          if (!e.target.files) return;
          if (!e.target.files.length) {
            setSelectedFilename("");
            props.onChange();
            return;
          }

          const f = e.target.files[0];
          setSelectedFilename(f.name);
          props.onChange(f);
        }}
        style={{ pointerEvents: "none" }}
      />

      <div
        className="flex-row-start-center"
        style={{
          color: !!selectedFilename ? "#000" : "var(--border-gray)",
          flex: 1,
        }}
      >
        {selectedFilename || placeholder}
      </div>

      <div style={{ width: "20px", height: "20px" }}>
        {!!selectedFilename ? (
          <img
            src={iconChangeCircle}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPlus}
            color="var(--yellow-dark)"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </div>
    </div>
  );
};

export default FileInput;

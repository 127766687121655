import { useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { TNoticeListItem } from "../../lib/types/notice.type";
import { api_noticeList } from "../../api/notice";
import { printFormattedDate } from "../../lib/util";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../lib/hooks/useIsMobile";

const NoticePage = () => {
  const isMobile = useIsMobile();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const navigate = useNavigate();

  const [from, setFrom] = useState(0);

  const [list, setList] = useState<TNoticeListItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const limit = useMemo(() => 20, []);
  const refEndFetch = useRef(false);

  useEffect(() => {
    if (inView) _fetch();
  }, [inView]);

  const _fetch = () => {
    if (refEndFetch.current) return;

    api_noticeList(from, limit).then((res) => {
      if (!res) return;
      if (res.totalCount) setTotalCount(res.totalCount);

      setList((prev) => [...prev, ...res.list]);
      setFrom((prev) => prev + res.list.length);
      if (res.list.length < limit) refEndFetch.current = true;
    });
  };

  return (
    <div className="news-page-wrapper">
      <div
        className={`news-page-inner ${
          isMobile ? "text-small" : "text-medium"
        } bold`}
      >
        <div
          className="news-page-header"
          style={isMobile ? { height: "100px" } : {}}
        >
          {!isMobile && <h2>공지사항</h2>}
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p>꿀단지의 서비스 안내와 공지에 대한 소식을 전해드립니다.</p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <p
              style={{
                width: "150px",
                height: "40px",
                textAlign: "center",
                marginTop: isMobile ? "14px" : 0,
              }}
            >
              총 12건
            </p>
          </div>
        </div>

        <div className="news-row">
          <span style={{ color: "#000", width: "100px" }}>번호</span>
          <span>{"  "}제목</span>
          <span style={{ color: "#000" }}>등록일</span>
        </div>

        {list.map((notice, i) => (
          <div className="news-row" key={notice.id}>
            <span style={{ color: "#000", width: "100px" }}>
              {totalCount - i}
            </span>
            <span
              onClick={() => {
                navigate(`/news/notice/${notice.id}`);
              }}
            >
              {notice.subject}
            </span>
            <span>
              {printFormattedDate(new Date(notice.createdAt), {
                seperator: ".",
              })}
            </span>
          </div>
        ))}

        <div ref={ref} style={{ width: "100%", height: "10px" }}></div>
      </div>
    </div>
  );
};

export default NoticePage;

import { useCallback } from "react";
import ModalBase from "./Base";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atom__confirmModalInfo,
  atom__findIdModalInfo,
  atom__findIdPwModalInfo,
  atom__findPwModalInfo,
} from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import BaseButtonOutline from "../Button/Outline";
import iconAlert from "../../assets/alert.png";
import iconInfo from "../../assets/info.png";

const ModalFindIdPw = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__findIdPwModalInfo
  );

  const r__setFindIdModalInfo = useSetRecoilState(atom__findIdModalInfo);
  const r__setFindPwModalInfo = useSetRecoilState(atom__findPwModalInfo);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);
  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-col-center-center">
        <img
          src={iconInfo}
          style={{ width: "48px", height: "48px", marginBottom: "10px" }}
        />
        <h2 style={{ marginBottom: "20px" }}>아이디/비밀번호 찾기</h2>
        <div style={{ marginBottom: "40px" }}>
          <p className="modal-desc">본인 인증으로 가입된 계정 정보 찾기</p>
          <p className="modal-desc">*비밀번호는 변경만 가능합니다.</p>
        </div>

        <div className="flex-row-between-center">
          <BaseButtonOutline
            onClick={() => {
              close();
              r__setFindIdModalInfo(true);
            }}
            text={"아이디 찾기"}
            style={{
              marginRight: "20px",
              width: "163px",
            }}
          />
          <BaseButtonOutline
            onClick={() => {
              close();
              r__setFindPwModalInfo(true);
            }}
            text={"비밀번호 변경"}
            style={{
              marginRight: "20px",
              width: "163px",
            }}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default ModalFindIdPw;

import React from "react";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";

const LineChart = (props: any) => {
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Line
      data={{
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        datasets: [
          {
            label: "# of Votes",
            data: props.data,
            backgroundColor: ["rgba(255, 206, 86, 0.2)"],
            borderColor: ["rgba(255, 206, 86, 1)"],
            borderWidth: 2,
          },
        ],
      }}
      options={options}
      style={{ ...props.style }}
    />
  );
};

export default LineChart;

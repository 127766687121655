const PostInfoRow = ({ label, info }: { label: string; info: string }) => {
  return (
    <div className="flex-row-start-center" style={{ padding: "4px 0" }}>
      <p
        className="text-small"
        style={{
          width: "90px",
          color: "var(--border-gray)",
          textAlign: "left",
        }}
      >
        {label}
      </p>
      <p
        className="text-small"
        style={{
          flex: 1,
          textAlign: "left",
        }}
      >
        {info}
      </p>
    </div>
  );
};

export default PostInfoRow;

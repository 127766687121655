import { useMediaQuery } from "react-responsive";

const useIsMobile = () => {
  const isMobile = useMediaQuery({
    // query: "(max-width: 768px)",
    query: "(max-width: 1080px)",
  });

  return isMobile;
};

export default useIsMobile;

import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__postMemoModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import { api_postMemoGet, api_postMemoSave } from "../../api/post";

const ModalPostMemo = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__postMemoModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [memo, setMemo] = useState("");
  const [initialMemo, setInitialMemo] = useState("");

  useEffect(() => {
    if (!r__modalInfo) {
      setMemo("");
      return;
    }

    api_postMemoGet(r__modalInfo.postId).then((res) => {
      if (!res) return;
      setMemo(res.memo ?? "");
      setInitialMemo(res.memo ?? "");
    });
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
    setMemo("");
  }, []);

  const save = async () => {
    if (!r__modalInfo) return;

    const res = await api_postMemoSave(r__modalInfo.postId, { memo });
    if (!res) return;

    close();
    r__setSuccessModalInfo({ desc: ["메모가 저장되었습니다."] });
    r__modalInfo.onSave?.();
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>매물 메모</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label className="text-small bold">메모</label>
      <textarea
        value={memo}
        onChange={(e) => setMemo(e.target.value)}
        style={{ marginBottom: "30px", height: "200px" }}
        placeholder="메모 내용 입력"
      ></textarea>

      <BaseButton text="저장" onClick={save} disabled={initialMemo === memo} />
    </ModalBase>
  );
};

export default ModalPostMemo;

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  items: { label: string; value: string }[];
  value?: string;
  style?: {};
  onChange: (value: string) => void;
}
const SelectSmall = (props: IProps) => {
  return (
    <div
      className="select-container"
      style={{ minWidth: "100px", height: "26px", ...props.style }}
    >
      <select
        onChange={(e) => props.onChange(e.target.value)}
        defaultValue={props.value}
      >
        {props.items.map(({ label, value }, i) => (
          <option key={i} value={value}>
            {label === "전월세" ? "월세" : label}
          </option>
        ))}
      </select>
      <span>
        <FontAwesomeIcon icon={faCaretDown} color="var(--yellow-dark)" />
      </span>
    </div>
  );
};
export default SelectSmall;

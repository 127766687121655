import useIsMobile from "../../lib/hooks/useIsMobile";
import GlobalNavigationBarMobile from "./mobile";
import GlobalNavigationBarPc from "./pc";

const GlobalNavigationBar = () => {
  const isMobile = useIsMobile();

  return isMobile ? <GlobalNavigationBarMobile /> : <GlobalNavigationBarPc />;
};

export default GlobalNavigationBar;

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom__complaintModalInfo,
  atom__leaveModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import ModalBase from "./Base";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import Select from "../Select";
import { api_complaint } from "../../api/user";
import { atom__me } from "../../lib/recoil/common.atom";
import { api_leave } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import Checkbox from "../Checkbox";
import BaseButtonOutline from "../Button/Outline";

const ModalLeave = () => {
  const navigate = useNavigate();
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__leaveModalInfo);
  const [r__me, r__setMe] = useRecoilState(atom__me);

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (!r__modalInfo) {
      setAgree(false);
      return;
    }
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const leave = async () => {
    if (!r__modalInfo) return;

    const res = await api_leave();
    if (!res) return;

    close();
    r__setSuccessModalInfo({
      desc: ["회원탈퇴가 완료되었습니다."],
      onClickBtn: () => {
        r__setSuccessModalInfo(null);
        navigate("/map");
      },
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>회원 탈퇴</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <p className="text-small">
        회원 탈퇴 시 등록하신 정보와 매물은 모두 삭제됩니다.
      </p>
      <p className="alert-desc">폐업이신가요?</p>
      <p className="text-small">
        소속 인원들의 소속 변경 및 회원 탈퇴가 모두 진행되었을까요?
      </p>
      <p className="text-small">
        일반 회원탈퇴가 아닌 폐업의 경우, 고객센터에 연락 바랍니다
      </p>

      <Checkbox
        label={"위 내용을 모두 확인했고, 회원탈퇴를 요청합니다."}
        checked={agree}
        onClick={() => setAgree((prev) => !prev)}
      />

      <div className="flex-row-between-center">
        <BaseButtonOutline
          onClick={close}
          text={"취소"}
          style={{
            borderColor: "var(--border-gray)",
            color: "var(--border-gray)",
            marginRight: "20px",
            width: "163px",
          }}
        />
        <BaseButton
          onClick={leave}
          text={"탈퇴"}
          disabled={!agree}
          style={{
            flex: 1,
            backgroundColor: "var(--tomato)",
            width: "163px",
          }}
        />
      </div>
    </ModalBase>
  );
};

export default ModalLeave;

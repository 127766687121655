import { RefObject, useEffect, useRef, useState } from "react";
import iconLogoFull from "../../assets/logo-full.png";
import SmallButton from "../../components/Button/Small";
import InputWithButton from "../../components/InputWithButton";
import {
  api_checkUid,
  api_confirmPhoneCode,
  api_sendPhoneCode,
} from "../../api/auth";
import { useSetRecoilState } from "recoil";
import {
  atom__simpleAlertModalInfo,
  atom__termModalInfo,
} from "../../lib/recoil/modal.atom";
import iconEyeOn from "../../assets/eye_on.png";
import iconEyeOff from "../../assets/eye_off.png";
import {
  regAuthCode,
  regEmail,
  regPhone,
  validatePw,
  validateUid,
} from "../../lib/validator";
import Checkbox from "../../components/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import BaseButton from "../../components/Button/Base";
import iconCancelWhite from "../../assets/cancel_white.png";
import ModalSimpleAlert from "../../components/Modal/SimpleAlert";
import { useNavigate } from "react-router-dom";
import { TTermKind } from "../../lib/types/term.type";
import { api_term } from "../../api/term";

const RegisterStep1Page = () => {
  const navigate = useNavigate();

  const r__setTermModalInfo = useSetRecoilState(atom__termModalInfo);
  const r__setSimpleAlertModalInfo = useSetRecoilState(
    atom__simpleAlertModalInfo
  );

  const [canNext, setCanNext] = useState(false);

  const [uid, setUid] = useState("");
  const [authedUid, setAuthedUid] = useState("");
  const [pw, setPw] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [authedPhone, setAuthedPhone] = useState("");
  const [authCode, setAuthCode] = useState("");

  const [pwSee, setPwSee] = useState(false);
  const [uidAlertDesc, setUidAlertDesc] = useState("");
  const [pwAlertDesc, setPwAlertDesc] = useState("");
  const [nameAlertDesc, setNameAlertDesc] = useState("");
  const [emailAlertDesc, setEmailAlertDesc] = useState("");
  const [phoneAlertDesc, setPhoneAlertDesc] = useState("");
  const [codeAlertDesc, setCodeAlertDesc] = useState("");

  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeService, setAgreeService] = useState(false);
  const [agreeLocation, setAgreeLocation] = useState(false);

  /** 휴대폰번호 인증 과정에서 잠시 사용되는 변수. '인증번호 발송'을 누르면 당시의 휴대폰번호가 이 변수에 저장되고, 이후 인증완료되면 이 변수의 값을 authedPhone에 저장함. */
  const refPhoneTemp = useRef("");

  // 자동하이픈
  useEffect(() => {
    if (phone.length === 10) {
      setPhone(phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phone.length === 13) {
      setPhone(
        phone.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phone]);

  useEffect(() => {
    setCanNext(
      agreeLocation &&
        agreePrivacy &&
        agreeService &&
        authedUid === uid &&
        !!authedPhone.length &&
        pw.length > 4 &&
        name.length >= 2 &&
        email.length > 3
    );
  }, [
    agreeLocation,
    agreePrivacy,
    agreeService,
    authedUid,
    uid,
    authedPhone,
    pw,
    name,
    email,
  ]);

  const checkUid = () => {
    // validate
    const validateResult = validateUid(uid);
    switch (validateResult) {
      case "fail:length":
        setUidAlertDesc("4~20자 사이로 입력해주세요.");
        return;
      case "fail:format":
        setUidAlertDesc("영문과 숫자만 입력해주세요.");
        return;
      case "pass":
        setUidAlertDesc("");
    }

    // api
    api_checkUid(uid).then((res) => {
      if (!res) return;

      if (!res.available) {
        r__setSimpleAlertModalInfo({
          desc: [res.message],
        });
        return;
      }

      // 중복확인 통과
      r__setSimpleAlertModalInfo({
        desc: ["사용 가능한 아이디입니다."],
        btnColor: "var(--yellow-dark)",
      });
      setAuthedUid(uid);
    });
  };

  const sendCode = () => {
    if (!regPhone.test(phone)) {
      setPhoneAlertDesc("올바른 휴대폰번호를 입력해주세요.");
      return;
    }

    api_sendPhoneCode(phone).then((res) => {
      if (!res) return;

      r__setSimpleAlertModalInfo({
        desc: ["인증번호가 발송되었습니다."],
        btnColor: "var(--yellow-dark)",
      });
      setPhoneAlertDesc("인증번호가 발송되었습니다.");
      setTimeout(() => {
        setPhoneAlertDesc("");
      }, 5000);

      refPhoneTemp.current = phone;
    });
  };
  const confirmCode = () => {
    if (!regAuthCode.test(authCode)) {
      setCodeAlertDesc("올바른 인증번호를 입력해주세요.");
      return;
    } else setCodeAlertDesc("");

    api_confirmPhoneCode({ phone, code: authCode }).then((res) => {
      if (!res) return;

      r__setSimpleAlertModalInfo({
        desc: ["인증 완료되었습니다."],
        btnColor: "var(--yellow-dark)",
      });

      setAuthedPhone(refPhoneTemp.current);
    });
  };

  const agreeAll = () => {
    if (agreePrivacy && agreeService && agreeLocation) {
      setAgreePrivacy(false);
      setAgreeService(false);
      setAgreeLocation(false);
    } else {
      setAgreePrivacy(true);
      setAgreeService(true);
      setAgreeLocation(true);
    }
  };

  const openTerm = (kind: "service" | "privacy" | "location") => {
    r__setTermModalInfo({
      kind,
      onAgree: () => {
        r__setTermModalInfo(null);
        switch (kind) {
          case "service":
            return setAgreeService(true);
          case "privacy":
            return setAgreePrivacy(true);
          case "location":
            return setAgreeLocation(true);
        }
      },
    });
  };

  const next = () => {
    if (!regEmail.test(email))
      return setEmailAlertDesc("올바른 형식의 이메일을 입력해주세요.");

    const validateResult = validatePw(pw);
    switch (validateResult) {
      case "fail:length":
        return setPwAlertDesc("비밀번호는 4~20자 사이로 입력해주세요.");
      case "fail:format":
        return setPwAlertDesc("비밀번호는 영문과 특수문자를 포함해야 합니다.");
      case "pass":
        break;
    }

    navigate("/register/step2", {
      state: {
        registerInfo: {
          uid: authedUid,
          pw,
          name,
          email,
          phone: authedPhone,
        },
      },
    });
  };

  return (
    <div
      className="flex-col-start-center"
      style={{
        height: "100%",
        width: "100%",
        padding: "20px",
      }}
    >
      <div
        className="flex-col-start-center"
        style={{ width: "344px", position: "relative" }}
      >
        <img src={iconLogoFull} style={{ width: "100%" }} />

        <h2 style={{ margin: "20px 0" }}>회원가입</h2>

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>아이디</label>
          <p className="alert-desc">{uidAlertDesc}</p>
        </div>
        <InputWithButton
          inputProps={{
            type: "text",
            placeholder: "4~20자 입력 (특수문자 불가)",
            maxLength: 20,
            value: uid,
            onChange: (e) => {
              setUid(e.target.value);
            },
          }}
          buttonProps={{
            text: "중복확인",
            onClick: checkUid,
          }}
          style={{ marginBottom: "14px" }}
        />

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>비밀번호</label>
          <p className="alert-desc">{pwAlertDesc}</p>
        </div>
        <div
          className="flex-row-center-center"
          style={{ width: "100%", position: "relative", marginBottom: "14px" }}
        >
          <input
            type={pwSee ? "text" : "password"}
            value={pw}
            placeholder="영문, 특수문자 포함 4~20자"
            onChange={(e) => setPw(e.target.value)}
            style={{ paddingRight: "32px" }}
          />
          <img
            src={pwSee ? iconEyeOff : iconEyeOn}
            onClick={() => setPwSee((prev) => !prev)}
            style={{
              width: "24px",
              height: "24px",
              position: "absolute",
              right: "8px",
              cursor: "pointer",
            }}
          />
        </div>

        <div style={{ width: "100%", marginBottom: "14px" }}>
          <div className="flex-row-between-center" style={{ width: "100%" }}>
            <label>이름</label>
            <p className="alert-desc">{nameAlertDesc}</p>
          </div>
          <input
            type="text"
            placeholder="이름 입력"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div style={{ width: "100%", marginBottom: "14px" }}>
          <div className="flex-row-between-center" style={{ width: "100%" }}>
            <label>이메일</label>
            <p className="alert-desc">{emailAlertDesc}</p>
          </div>
          <input
            type="text"
            placeholder="hello_howmuch@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>휴대전화</label>
          <p className="alert-desc">{phoneAlertDesc}</p>
        </div>
        <InputWithButton
          inputProps={{
            type: "text",
            placeholder: "010-0000-0000",
            maxLength: 13,
            readOnly: phone.length > 0 && phone === authedPhone,
            value: phone,
            onChange: (e) => {
              setPhone(e.target.value);
            },
          }}
          buttonProps={{
            text: "인증번호 발송",
            disabled: phone === authedPhone,
            onClick: sendCode,
          }}
          style={{ marginBottom: "14px" }}
        />

        <div className="flex-row-between-center" style={{ width: "100%" }}>
          <label>인증번호</label>
          <p className="alert-desc">{codeAlertDesc}</p>
        </div>
        <InputWithButton
          inputProps={{
            type: "text",
            placeholder: "인증번호 4자리",
            maxLength: phone !== authedPhone ? 4 : undefined,
            value:
              !phone.length || phone !== authedPhone
                ? authCode
                : "인증이 완료되었습니다.",
            onChange: (e) => {
              setAuthCode(e.target.value);
            },
          }}
          buttonProps={{
            text: "인증하기",
            disabled: phone === authedPhone,
            onClick: confirmCode,
          }}
          style={{ marginBottom: "14px" }}
        />

        <Checkbox
          label="전체 동의"
          checked={agreePrivacy && agreeService && agreeLocation}
          onClick={agreeAll}
          style={{ width: "100%", marginBottom: "12px" }}
        />

        <div
          style={{ width: "100%", borderTop: "1px dotted var(--border-gray)" }}
        ></div>

        <div
          className="flex-row-start-center"
          style={{ width: "100%", margin: "12px 0" }}
        >
          <Checkbox
            label="개인정보처리방침 동의"
            checked={agreePrivacy}
            onClick={() => setAgreePrivacy((prev) => !prev)}
            style={{ flex: 1 }}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => openTerm("privacy")}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          className="flex-row-start-center"
          style={{ width: "100%", marginBottom: "12px" }}
        >
          <Checkbox
            label="기본 이용약관 동의"
            checked={agreeService}
            onClick={() => setAgreeService((prev) => !prev)}
            style={{ flex: 1 }}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => openTerm("service")}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          className="flex-row-start-center"
          style={{ width: "100%", marginBottom: "40px" }}
        >
          <Checkbox
            label="위치기반서비스 이용약관 동의"
            checked={agreeLocation}
            onClick={() => setAgreeLocation((prev) => !prev)}
            style={{ flex: 1 }}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => openTerm("location")}
            style={{ cursor: "pointer" }}
          />
        </div>

        <BaseButton
          text="다음"
          onClick={next}
          disabled={!canNext}
          style={{ marginBottom: "20px" }}
        />
      </div>
    </div>
  );
};

export default RegisterStep1Page;

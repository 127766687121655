import { TListQuery } from "../lib/types/common.type";
import { TPost } from "../lib/types/post.type";
import axios from "./axios";

export const api_postRegister = async (payload: any) => {
  const res = await axios.post("/post", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { id: string };
};

export const api_uploadPostImages = async (postId: string, files: File[]) => {
  const fd = new FormData();
  fd.append("postId", postId);
  for (const f of files) fd.append("files", f);
  const res = await axios.post("/post/image/upload", fd, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_postListMine = async (query: TListQuery) => {
  const res = await axios.get("/post/mine", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TPost[]; totalCount?: number };
};
export const api_postWishList = async (query: TListQuery) => {
  const res = await axios.get("/post/wish", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TPost[]; totalCount?: number };
};

export const api_postTradeDoneMine = async (postId: string) => {
  const res = await axios.post(`/post/${postId}/done-request`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { id: string };
};

export const api_postTradeDone = async (payload: {
  apartId: string;
  dong: string;
  ho: string;
}) => {
  const res = await axios.post(`/post/done-request`, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { id: string };
};

export const api_postMemoGet = async (postId: string) => {
  const res = await axios.get(`/post/${postId}/wish/memo`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { memo: string };
};
export const api_postMemoSave = async (
  postId: string,
  payload: {
    memo: string;
  }
) => {
  const res = await axios.post(`/post/${postId}/wish/memo`, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_postWish = async (postId: string, wished: boolean) => {
  const res = await axios.post(`/post/${postId}/wish/${wished ? "y" : "n"}`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { wished: boolean };
};

export const api_postListOnMap = async (apartId: string) => {
  const res = await axios.get(`/post/apart`, { params: { apartId } });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { posts: TPost[] };
};

export const api_postDetail = async (postId: string) => {
  const res = await axios.get(`/post/${postId}`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {
    post: TPost & { badgeWorth: number; wish: boolean };
  };
};

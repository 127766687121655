import { Link, useLocation } from "react-router-dom";
import { GNB_MENU } from "./menu";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { atom__me } from "../../../lib/recoil/common.atom";

const SubBar = () => {
  const pathName = useLocation().pathname;
  const r__me = useRecoilValue(atom__me);

  // 표시하지 않을 경로들
  if (
    pathName === "/map" ||
    pathName === "/post" ||
    pathName.startsWith("/register")
  )
    return null;

  return (
    <div className="flex-row-center-center sub-bar-container">
      {GNB_MENU.find((menu) => pathName.startsWith(menu.path))
        ?.items.filter((subMenu) => {
          if (!subMenu.authorizedLevel) return true;
          if (!r__me) return true;

          // 레벨 필터
          if (subMenu.authorizedLevel > r__me.level) return false;
          // 멤버십 필터
          if (subMenu.needMembership && !r__me.activeMembershipId) return false;

          return true;
        })
        .map((subMenu) => {
          return (
            // <div className="submenu" key={subMenu.path}>
            <Link
              key={subMenu.path}
              to={subMenu.path}
              className={pathName.startsWith(subMenu.path) ? "current" : ""}
            >
              {subMenu.name}
            </Link>
            // </div>
          );
        })}
    </div>
  );
};
export default SubBar;

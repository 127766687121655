import { useNavigate } from "react-router-dom";
import BaseButton from "../../components/Button/Base";
import { useRecoilValue } from "recoil";
import { atom__me } from "../../lib/recoil/common.atom";
import useIsMobile from "../../lib/hooks/useIsMobile";

const PostStartPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const r__me = useRecoilValue(atom__me);

  return (
    <div
      className="flex-col"
      style={{
        height: "100%",
        paddingTop: "90px",
        paddingLeft: isMobile ? "20px" : "100px",
        paddingRight: isMobile ? "20px" : "100px",
      }}
    >
      {!isMobile && <h2>매물 등록 요청하기</h2>}
      <div
        className="flex-col-center-center"
        style={{ width: "100%", flex: 1, padding: 12 }}
      >
        {r__me ? (
          <div>
            <p className="text-small">
              아파트 꿀매물 저장소 꿀단지는 무료 매물등록이 가능합니다.
            </p>
            <br />
            <p className="text-small">
              단, 등록매물은 일반인이 아닌 꿀단지 중개회원의 아파트 매물공급망
              자료로 이용됨으로
            </p>
            <p className="text-small">
              단지내 부동산과 로컬 주택중개 부동산의 공동중개활성화 목적 취지에
              맞추어 제공하는 서비스 입니다.
            </p>
            <br />
            <p className="text-small">
              매물등록자가 공동중개 거부 / 허위매물 등록 사례에 대한 신고가 3회
              이상 접수될 경우 서비스 이용이 제한될 수 있습니다.
            </p>
          </div>
        ) : (
          <div style={isMobile ? {} : { width: "50%" }}>
            <p className="text-small">
              아파트 꿀매물 저장소 꿀단지는 무료 매물등록이 가능합니다.
            </p>
            <br />
            <p className="text-small">
              소유주시라면 여러부동산에 발품하여 위탁 하실 필요없이 별도
              회원가입이 필요없는 직접등록으로 지역 부동산 전체에 매물을
              내놓으시는 효과를 통해 수고스러움과 시간을 절약(단, 소유주
              등록매물에 대하여 검수 시 소유주 확인이 되지않거나 대리인의 사칭일
              경우 등록이 반려될 수 있습니다.)
            </p>
            <p className="text-small">
              중개회원이시라면 로그인 후 매물공급망 이용과 무제한 무료 매물등록
              가능(중개회원의 경우 로그인 전 매물등록을 지원하지 않습니다.)
            </p>
            <br />
            <p className="text-small">
              매물등록자가 (공동)중개 거부 / 허위매물 등록 사례에 대한 신고가
              3회 이상 접수될 경우 서비스 이용이 제한될 수 있습니다.
            </p>
          </div>
        )}

        <BaseButton
          text="매물등록 시작"
          onClick={() => {
            if (!r__me) return navigate("/post/step0");
            else
              return navigate("/post/step1", {
                state: { creatorUserId: r__me.id },
              });
          }}
          style={{ width: "344px", marginTop: "100px" }}
        />
      </div>
    </div>
  );
};

export default PostStartPage;

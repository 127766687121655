import { useRecoilState } from "recoil";
import { useCallback, useEffect, useRef, useState } from "react";
import { atom__termModalInfo } from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import ModalBaseYellow from "./BaseYellow";
import { api_term } from "../../api/term";

const ModalTerm = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__termModalInfo);

  const refContentContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!r__modalInfo) {
      // @ts-ignore
      refContentContainer.current?.setHTMLUnsafe("");
      return;
    }

    api_term(r__modalInfo.kind).then((res) => {
      if (!res) return;
      if (!refContentContainer.current) return;

      // @ts-ignore
      refContentContainer.current.setHTMLUnsafe(res);
    });
  }, [r__modalInfo]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  return (
    <ModalBaseYellow
      visible={!!r__modalInfo}
      onClickOuter={close}
      title={
        r__modalInfo?.kind === "service"
          ? "기본 이용약관"
          : r__modalInfo?.kind === "privacy"
          ? "개인정보처리방침"
          : "위치기반서비스 이용약관"
      }
    >
      <div
        style={{
          width: "100%",
          flex: 1,
          padding: "20px",
        }}
      >
        <div
          style={{
            padding: "20px 0",
            width: "100%",
            maxHeight: "500px",
            overflowY: "scroll",
          }}
          ref={refContentContainer}
        ></div>
        {!!r__modalInfo?.onAgree && (
          <BaseButton text="동의하기" onClick={r__modalInfo.onAgree} />
        )}
      </div>
    </ModalBaseYellow>
  );
};

export default ModalTerm;

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import {
  atom__changeOfficeModalInfo,
  atom__searchOfficeModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";
import ModalBaseYellow from "./BaseYellow";
import FileInput from "../FileInput";
import { atom__me } from "../../lib/recoil/common.atom";
import FakeSearchInput from "../FakeSearchInput";
import { uploadFile } from "../../lib/upload-file";
import { api_officeChange } from "../../api/office";

const ModalChangeOffice = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__changeOfficeModalInfo
  );

  const r__setSearchOfficeModalInfo = useSetRecoilState(
    atom__searchOfficeModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const r__me = useRecoilValue(atom__me);

  const [file2, setFile2] = useState<File | undefined>();
  const [file3, setFile3] = useState<File | undefined>();

  const [selectedOffice, setSelectedOffice] = useState({ id: "", name: "" });

  const [canNext, setCanNext] = useState(false);

  useEffect(() => {
    if (!r__modalInfo) {
      setFile2(undefined);
      setFile3(undefined);
      setSelectedOffice({ id: "", name: "" });

      return;
    }
  }, [r__modalInfo]);

  useEffect(() => {
    if (!r__me) return;

    const commonCondition = !!file2 && !!selectedOffice.id;
    // 중개보조원
    if (r__me.level === 40) return setCanNext(commonCondition);
    // 소공
    else return setCanNext(commonCondition && !!file3);
  }, [file2, file3, selectedOffice, r__me]);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const change = async () => {
    if (!r__me) return;

    let imageUrlOffice: string;
    let imageUrlCert: string | undefined;

    // 이미지(사무소등록증): 모두
    if (!file2) return;
    const uploadFile2 = await uploadFile(file2);
    if (!uploadFile2) return;
    imageUrlOffice = uploadFile2.url;

    // 이미지(중개사자격증): 소공만
    if (r__me.level === 70) {
      if (!file3) return;
      const uploadFile3 = await uploadFile(file3);
      if (!uploadFile3) return;
      imageUrlCert = uploadFile3.url;
    }

    const res = await api_officeChange({
      officeId: selectedOffice.id,
      changingImageUrlOffice: imageUrlOffice,
      changingImageUrlCert: imageUrlCert,
    });
    if (!res) return;

    close();
    r__setSuccessModalInfo({
      desc: ["사무소 변경이 정상적으로 요청되었습니다."],
    });
    r__modalInfo?.onChange();
  };

  // 모달을 닫았다가 다시 열었을 때 <FileInput />의 이전 value가 남아있지 않도록, 모달이 닫힐 때 하위컴포넌트들 언마운트시켜버림
  if (!r__modalInfo) return null;

  return (
    <ModalBaseYellow
      visible={!!r__modalInfo}
      onClickOuter={close}
      title="소속사무소 변경"
    >
      <div
        className="flex-col-between-center"
        style={{
          padding: "20px",
          width: "100%",
          flex: 1,
        }}
      >
        <div style={{ width: "100%" }}>
          <label>변경 희망 중개사무소</label>
          <FakeSearchInput
            label={selectedOffice.name}
            placeholder="중개사무소 검색"
            onClick={() => {
              r__setSearchOfficeModalInfo({
                onSelect: (id, name) => {
                  setSelectedOffice({ id, name });
                },
              });
            }}
            style={{
              marginBottom: "24px",
            }}
          />

          <label>중개사무소등록증</label>
          <FileInput
            onChange={(f) => {
              setFile2(f);
            }}
          />

          {r__me?.level === 70 && (
            <>
              <label style={{ marginTop: "24px" }}>중개사 자격증</label>
              <FileInput
                onChange={(f) => {
                  setFile3(f);
                }}
              />
            </>
          )}
        </div>

        <BaseButton
          text="변경 요청"
          onClick={change}
          disabled={!canNext}
          style={{ marginTop: "60px" }}
        />
      </div>
    </ModalBaseYellow>
  );
};

export default ModalChangeOffice;

import { useRecoilState, useRecoilValue } from "recoil";
import { atom__menuModalInfo } from "../../lib/recoil/modal.atom";
import { useCallback } from "react";
import iconArrowBack from "../../assets/arrow-left-black.png";
import { atom__me } from "../../lib/recoil/common.atom";
import { GNB_MENU_MOBILE } from "../GNB/mobile/menu";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ModalMenu = () => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();

  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__menuModalInfo);
  const r__me = useRecoilValue(atom__me);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  if (!r__modalInfo) return null;

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        overflowY: "auto",
      }}
    >
      {/* title row */}
      <div
        className="flex-row-between-center"
        style={{ width: "100%", height: "48px" }}
      >
        <div
          className="flex-row-center-center"
          style={{ width: "48px", height: "48px" }}
          onClick={close}
        >
          <img src={iconArrowBack} style={{ width: "24px", height: "24px" }} />
        </div>
        <p className="text-medium bold">메뉴</p>
        <span
          style={{ width: "48px", height: "48px", backgroundColor: "#fff" }}
        ></span>
      </div>

      {GNB_MENU_MOBILE.filter(
        (menu) =>
          menu.authorizedLevel <= (r__me?.level || 0) &&
          (!menu.needMembership ? true : !!r__me?.activeMembershipId)
      ).map((menu, i) => {
        return (
          <div
            key={i}
            style={{
              width: "100%",
              padding: "16px",
              borderTop: "1px solid #e5e5e5",
            }}
          >
            <p
              className="text-small"
              style={{ color: "var(--border-gray)", marginBottom: "6px" }}
            >
              {menu.category}
            </p>
            {menu.items
              .filter(
                (subMenu) =>
                  (subMenu.authorizedLevel || 0) <= (r__me?.level || 0) &&
                  (!subMenu.needMembership ? true : !!r__me?.activeMembershipId)
              )
              .map((subMenu, i) => {
                return (
                  <a
                    className="text-small bold"
                    key={i}
                    onClick={() => {
                      if (pathName === subMenu.path) return;

                      close();
                      navigate(subMenu.path);
                    }}
                    style={{
                      padding: "10px 0",
                      color:
                        pathName === subMenu.path
                          ? "var(--yellow-dark)"
                          : "#000",
                      textDecoration: "none",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    {subMenu.name}
                  </a>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default ModalMenu;

/** 채널톡 링크 */
export const KAKAO_LINK = `http://pf.kakao.com/_MeIhn`;

export const printFormattedDate = (
  date: Date | null,
  options?: { seperator?: string }
) => {
  if (!date) return "";

  const seperator = options?.seperator ?? ".";

  const y = date.getFullYear();
  const M = (date.getMonth() + 1 + "").padStart(2, "0");
  const d = (date.getDate() + "").padStart(2, "0");
  return `${y}${seperator}${M}${seperator}${d}`;
};

export const printFormattedDateKr = (
  date: Date | null,
  options?: { noDate?: boolean }
) => {
  if (!date) return "";

  const y = date.getFullYear();
  const M = (date.getMonth() + 1 + "").padStart(2, "0");
  if (options?.noDate) return `${y}년 ${M}월`;

  const d = (date.getDate() + "").padStart(2, "0");
  return `${y}년 ${M}월 ${d}일`;
};

export const insertComma = (origin: number) => {
  let isPlus = true;
  if (origin < 0) {
    isPlus = false;
  }
  const strMoney = Math.abs(origin).toString();
  const firstPartLength = strMoney.length % 3;
  const firstPart = strMoney.slice(0, firstPartLength);
  const rest = strMoney.slice(firstPartLength);

  if (!rest) return firstPart;

  let restPart = "";
  let cnt = 0;
  for (let i = 0; i < rest.length; i++) {
    restPart += rest[i];
    if (++cnt === 3) {
      cnt = 0;
      restPart += ",";
    }
  }
  restPart = restPart.substring(0, restPart.length - 1);
  const result = firstPart ? firstPart + "," + restPart : restPart;
  return isPlus ? result : "-" + result;
};

export const printCost = (
  manwon: number,
  options?: { manwonLabel?: string }
) => {
  const uk = Math.floor(manwon / 10000);
  const rest = manwon % 10000;
  if (!rest) return `${uk ? uk + "억 " : ""}`;

  const commaManwon = insertComma(rest);
  return `${uk ? uk + "억 " : ""}${commaManwon}${options?.manwonLabel || ""}`;
};

/** "11.4억"과 같이 억단위를 소숫점 1자리까지 표시. 1억 미만이면 "4000만"과 같이 만원단위만 표시 */
export const printCostPreferUk = (manwon: number) => {
  const uk = manwon / 10000;

  if (uk >= 1) return `${uk.toFixed(1).replace(".0", "")}억`; // 1.0억은 1억으로 표시되도록 replace()

  return `${manwon}만`;
};

/** "56만 1000원"과 같이 출력 */
export const printCostPreferMan = (manwon: number) => {
  const man = Math.floor(manwon / 10000);
  const rest = Math.floor(manwon % 10000);
  if (!rest) return `${man}만원`;

  return `${man}만 ${rest}원`;
};

export const convertToPyeong = (size: number) => {
  return Math.floor(size / 3.3025);
};

export const printFileSize = (bytes: number) => {
  const kbs = bytes / 1024;
  if (kbs < 0.1) return `${bytes} B`;

  const mbs = kbs / 1024;
  if (mbs < 0.1) {
    const kbsCut = (Math.ceil(kbs * 10) / 10).toFixed(1);
    return `${kbsCut} KB`;
  }

  const gbs = mbs / 1024;
  if (gbs < 0.1) {
    const mbsCut = (Math.ceil(mbs * 10) / 10).toFixed(1);
    return `${mbsCut} MB`;
  }

  const gbsCut = (Math.ceil(gbs * 10) / 10).toFixed(1);
  return `${gbsCut} GB`;
};

export const getOS = () => {
  // 디바이스 정보를 감지
  const userAgent = navigator.userAgent;

  const isAndroid = /android/i.test(userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

  if (/android/i.test(userAgent)) {
    return "android"; // Android인 경우
  }

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "ios"; // iOS인 경우
  }

  return "web"; // 감지되지 않는 경우
};

export const 기다려 = async (sec) => {
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, sec);
  });
};

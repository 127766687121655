import { useEffect, useRef, useState } from "react";
import { api_badgeList, api_myBadgeList } from "../../api/badge";
import {
  EOfficeBadgeStatus,
  TBadge,
  TOfficeBadge,
} from "../../lib/types/badge.type";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilver from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import useIsMobile from "../../lib/hooks/useIsMobile";
import SmallButton from "../../components/Button/Small";
import BadgeInfoBox from "../../components/Badge/BadgeInfoBox";
import MyBadge from "../../components/Badge/MyBadge";
import { useSetRecoilState } from "recoil";
import {
  atom__purchaseBadgeModalInfo,
  atom__searchApartModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";

const BadgePage = () => {
  const isMobile = useIsMobile();

  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setSearchApartModalInfo = useSetRecoilState(
    atom__searchApartModalInfo
  );
  const r__setPurchaseBadgeModalInfo = useSetRecoilState(
    atom__purchaseBadgeModalInfo
  );

  const [availableBadge, setAvailableBadge] = useState<TBadge | null>(null);
  const [cannotApplyMsg, setCannotApplyMsg] = useState("");
  const [myBadges, setMyBadges] = useState<TOfficeBadge[]>([]);
  const [postCount, setPostCount] = useState(0);

  const [badgeItems, setBadgeItems] = useState<
    (TBadge & { available: boolean })[]
  >([]);

  // 구매신청 누른 배지. 단지 선택 완료되면 api호출할때 사용됨.
  const refPurchasingBadge = useRef<TBadge | null>(null);

  useEffect(() => {
    _fetch();
  }, []);

  const _fetch = () => {
    api_myBadgeList().then((res) => {
      if (!res) return;
      setAvailableBadge(res.availableBadge);
      setCannotApplyMsg(res.cannotApplyMsg ?? "");
      setMyBadges(res.list);
      setPostCount(res.postCount);
    });
  };

  const onClickPurchase = () => {
    api_badgeList().then((res) => {
      if (!res) return;
      setBadgeItems(res.list);
    });
  };

  const onPurchaseBage = () => {
    r__setSuccessModalInfo({
      desc: [
        "구매 신청이 정상적으로 접수되었습니다.",
        "관리자 확인 후 문자안내 예정입니다.",
      ],
    });
    _fetch();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      {/* left */}

      <div
        style={{
          width: isMobile ? "100%" : "700px",
          height: "100%",
          position: "inherit",
          padding: "20px 60px 0",
          overflowY: "auto",
        }}
      >
        {/* title */}
        <div
          className="flex-row"
          style={{ alignItems: "flex-end", marginBottom: "32px" }}
        >
          <h2>배지 달성</h2>
          <p
            className="text-small"
            style={{ color: "var(--border-gray)", marginLeft: "24px" }}
          >
            배지 동일단지 중복적용불가
          </p>
        </div>

        {/* row1 */}
        <div className="flex-row-start-center" style={{ marginBottom: "32px" }}>
          <p
            className="text-small bold"
            style={{ width: "120px", marginRight: "20px" }}
          >
            가능한 배지 상품
          </p>
          <div className="flex-row-start-center" style={{ width: "200px" }}>
            {availableBadge ? (
              <>
                <img
                  src={
                    availableBadge.worth === 50
                      ? iconMedalGold
                      : availableBadge.worth === 30
                      ? iconMedalSilver
                      : iconMedalBronze
                  }
                  style={{ width: "32px", height: "32px", marginRight: "10px" }}
                />
                <p className="text-medium bold">{availableBadge.name}</p>
              </>
            ) : (
              <p
                className="text-medium bold"
                style={{ width: "100%", textAlign: "center" }}
              >
                -
              </p>
            )}
          </div>
        </div>
        {/* row2 */}
        <div className="flex-row-start-center" style={{ marginBottom: "40px" }}>
          <p
            className="text-small bold"
            style={{ width: "120px", marginRight: "20px" }}
          >
            등록 매물
          </p>
          <p
            className="text-medium bold"
            style={{ width: "200px", textAlign: "center" }}
          >
            {postCount}건
          </p>
        </div>

        <div
          className="flex-row-between-center"
          style={{ marginBottom: "35px" }}
        >
          <h2>내 배지</h2>
          <div className="flex-col" style={{ alignItems: "flex-end" }}>
            <SmallButton
              text="구매하기"
              onClick={onClickPurchase}
              disabled={!!cannotApplyMsg}
              color="beige"
              width={100}
            />
            {!!cannotApplyMsg && <p className="alert-desc">{cannotApplyMsg}</p>}
          </div>
        </div>

        {myBadges.map((ob) => {
          return (
            <MyBadge
              key={ob.id}
              id={ob.id}
              badge={ob.badge}
              apartName={ob.apart.kaptName}
              startDate={ob.startDate}
              endDate={ob.endDate}
              isPending={ob.status === EOfficeBadgeStatus.PENDING}
              style={{ marginBottom: "20px" }}
            />
          );
        })}
      </div>

      {!isMobile && (
        <div
          style={{
            width: "10px",
            height: "100%",
            borderLeft: "1px solid var(--border-gray)",
            borderRight: "1px solid var(--border-gray)",
          }}
        />
      )}

      {/* right */}
      {!badgeItems.length ? null : (
        <>
          <div
            style={
              isMobile
                ? {
                    backgroundColor: "#fff",
                    width: "100%",
                    padding: "38px 24px 0",
                    height: "100%",
                    overflowY: "auto",
                    position: "absolute",
                  }
                : {
                    flex: 1,
                    padding: "20px 60px 0",
                    height: "100%",
                    overflowY: "auto",
                  }
            }
          >
            <h2 style={{ marginBottom: "40px" }}>배지 상품 소개</h2>
            <div style={{ marginBottom: "40px" }}>
              <p>*구매시 유의사항</p>
              {/* <br /> */}
              <br />
              <p>
                현재 달성한 최고 등급 메달 배지만 구매 신청이 가능하며, 다른
                메달 배지는 구매 신청이 불가합니다.
              </p>
              <p>
                메달 배지 구매시 최초 단지 지정은 필수이며, 이후 다른 단지
                등록권은 추가 구매가 가능합니다.
              </p>
              <p>
                같은 단지의 동일 메달 배지의 경우, 등록권 총 구매수를 기준으로
                정렬되며, 구매수가 동일한 경우에는 최근 구매자부터 노출됩니다.
              </p>
            </div>
            <div
              className={
                isMobile ? "flex-col-start-center" : "flex-row-start-center"
              }
            >
              {badgeItems.map((b) => {
                return (
                  <div
                    key={b.id}
                    style={{
                      marginRight: isMobile ? 0 : "20px",
                      marginBottom: isMobile ? "40px" : "24px",
                    }}
                  >
                    <BadgeInfoBox badge={b} style={{ marginBottom: "20px" }} />
                    <SmallButton
                      style={{ width: "100%" }}
                      text="구매 신청"
                      onClick={() => {
                        refPurchasingBadge.current = b;
                        r__setSearchApartModalInfo({
                          desc: "배지 구매 후 상단 노출되는 단지를 선택해주세요.",
                          descAlert: "*단지는 최초 지정 후 변경이 불가합니다.",
                          onSelect: (id, name) => {
                            if (!refPurchasingBadge.current) return;
                            r__setSearchApartModalInfo(null);
                            r__setPurchaseBadgeModalInfo({
                              badge: refPurchasingBadge.current,
                              apart: { id, name },
                              onSuccess: onPurchaseBage,
                            });
                          },
                        });
                      }}
                      color="beige"
                      width={100}
                      disabled={!b.available}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default BadgePage;

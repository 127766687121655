import Axios from "axios";

export type TFetchRes<T = any> = IFetchSuccess<T> | IFetchError;

export interface IFetchSuccess<T = {}> {
  success: true;
  data: T;
}
export interface IFetchError {
  success: false;
  err: {
    msg: string;
    code?: string;
  };
}
// const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL =
  window.location.protocol === "https:"
    ? "https://api.honeyaptdanji.com"
    : "http://localhost:5000";

const axios = Axios.create({
  baseURL: BASE_URL,
});

axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("at")}`,
};
axios.defaults.withCredentials = true;

export default axios;

import { useEffect, useMemo, useRef, useState } from "react";
import { api_newsList } from "../../api/news";
import { TNews } from "../../lib/types/news.type";
import { printFormattedDate } from "../../lib/util";
import { useInView } from "react-intersection-observer";
import useIsMobile from "../../lib/hooks/useIsMobile";

const NewsGlobalPage = () => {
  const isMobile = useIsMobile();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [from, setFrom] = useState(0);

  const [list, setList] = useState<TNews[]>([]);

  const limit = useMemo(() => 20, []);
  const refEndFetch = useRef(false);

  useEffect(() => {
    if (inView) _fetch();
  }, [inView]);

  const _fetch = () => {
    if (refEndFetch.current) return;

    api_newsList(from, limit, "전국").then((res) => {
      if (!res) return;
      setList((prev) => [...prev, ...res.list]);
      setFrom((prev) => prev + res.list.length);
      if (res.list.length < limit) refEndFetch.current = true;
    });
  };

  return (
    <div className="news-page-wrapper">
      <div
        className={`news-page-inner ${
          isMobile ? "text-small" : "text-medium"
        } bold`}
      >
        {!isMobile && (
          <div className="news-page-header">
            <h2>뉴스 (전국)</h2>
          </div>
        )}

        <div className="news-row">
          <span style={{ color: "#000" }}>출처</span>
          <span>{"  "}제목</span>
          <span style={{ color: "#000" }}>등록일</span>
        </div>

        {list.map((news) => (
          <div className="news-row" key={news.id}>
            <span>[{news.source}]</span>
            <span
              onClick={() => {
                window.open(news.url, "_blank");
              }}
            >
              {news.subject}
            </span>
            <span>
              {printFormattedDate(new Date(news.createdAt), { seperator: "." })}
            </span>
          </div>
        ))}

        <div ref={ref} style={{ width: "100%", height: "10px" }}></div>
      </div>
    </div>
  );
};

export default NewsGlobalPage;

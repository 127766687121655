import { useEffect } from "react";

const RootPage = () => {
  useEffect(() => {
    window.location.replace("/map");
  }, []);
  return <div></div>;
};

export default RootPage;

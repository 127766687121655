import useIsMobile from "../../../lib/hooks/useIsMobile";
import ModalUserDetailMobile from "./mobile";
import ModalUserDetailPc from "./pc";

const ModalUserDetail = () => {
  const isMobile = useIsMobile();

  if (isMobile) return <ModalUserDetailMobile />;
  else return <ModalUserDetailPc />;
};
export default ModalUserDetail;

import React, { useEffect, useState } from "react";
import useIsMobile from "../../lib/hooks/useIsMobile";
import {
  TEmployeeDetail,
  TEmployeeListItem,
  USER_LEVEL_TO_LABEL,
} from "../../lib/types/user.type";
import profileDefault from "../../assets/profile-default-big.png";
import { TPostListItemWithStatus } from "../../lib/types/post.type";
import {
  api_employeeDetail,
  api_employeeList,
  api_employeePostList,
} from "../../api/user";
import {
  convertToPyeong,
  printCostPreferUk,
  printFormattedDate,
} from "../../lib/util";
import postImageDefault from "../../assets/post-default-116_116.png";
import AreaTypeBox from "../../components/Post/AreaTypeBox";
import SmallButton from "../../components/Button/Small";

interface IPostListItemWithStatusProps {
  post: TPostListItemWithStatus;
  style?: React.CSSProperties;
  onClick?: () => void;
}
const PostListItemWithStatus = (props: IPostListItemWithStatusProps) => {
  return (
    <div
      style={{
        width: "480px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 4px 0px #00000040",
        padding: "10px 16px 10px",
        cursor: props.onClick ? "pointer" : "default",
        ...(props.style ?? {}),
      }}
    >
      {/* 상단 */}
      <div className="flex-row-between-center" style={{ marginBottom: "34px" }}>
        <img
          src={props.post.thumbnailUrl || postImageDefault}
          style={{ width: "60px", height: "60px", marginRight: "14px" }}
        />
        <div style={{ flex: 1 }}>
          <div
            className="flex-row-between-center"
            style={{ marginBottom: "18px" }}
          >
            <p className="text-medium">{props.post.apartName}</p>
            <p className="text-small" style={{ color: "var(--beige)" }}>
              {props.post.saleKind}
            </p>
          </div>
          <div className="flex-row-start-center">
            <p
              className="text-small"
              style={{ color: "var(--border-gray)", flex: 1 }}
            >
              {props.post.as3}
            </p>
            <p className="text-medium bold">
              {convertToPyeong(props.post.exclusiveArea)}평
            </p>
            <AreaTypeBox
              areaType={props.post.areaType}
              style={{ margin: "0 20px" }}
            />
            <p className="text-big bold" style={{ color: "var(--brown)" }}>
              {printCostPreferUk(props.post.costOnce)}
              {props.post.costMonth
                ? `/${printCostPreferUk(props.post.costMonth)}`
                : ""}
            </p>
          </div>
        </div>
      </div>

      {/* 하단: 등록일자, 판매상태 */}
      <div className="flex-row-start-center">
        <p className="text-small" style={{ marginRight: "10px" }}>
          등록일
        </p>
        <p className="text-medium" style={{ flex: 1 }}>
          {printFormattedDate(new Date(props.post.createdAt))}
        </p>
        <SmallButton
          text={props.post.tradeStatus === "done" ? "판매완료" : "판매중"}
          color="green"
          disabled={props.post.tradeStatus === "done"}
          width={100}
        />
      </div>
    </div>
  );
};

// ---------

const EmployeePage = () => {
  const isMobile = useIsMobile();

  const [employeeList, setEmployeeList] = useState<TEmployeeListItem[]>([]);

  const [selectedUserId, setSelectedUserId] = useState("");
  const [userDetail, setUserDetail] = useState<TEmployeeDetail | null>(null);
  const [posts, setPosts] = useState<TPostListItemWithStatus[]>([]);
  const [filterWithTradeIng, setFilterWithTradeIng] = useState(true);
  const [filterWithTradeDone, setFilterWithTradeDone] = useState(true);

  // 직원목록 fetch
  useEffect(() => {
    api_employeeList().then((res) => {
      if (!res) return;
      setEmployeeList(res.list);
    });
  }, []);

  // 직원상세 fetch (등록매물 목록은 따로)
  useEffect(() => {
    if (!selectedUserId) {
      setUserDetail(null);
      return;
    }

    api_employeeDetail(selectedUserId).then((res) => {
      if (!res) return;
      setUserDetail(res.employee);
    });
  }, [selectedUserId]);

  // 직원의 등록매물 목록 fetch
  useEffect(() => {
    if (!userDetail) {
      setPosts([]);
      return;
    }

    const userId = userDetail.id;

    if (employeeList.find((user) => user.id === userId)?.postCount === 0)
      return setPosts([]);

    api_employeePostList({
      userId,
      withTradeIng: filterWithTradeIng ? "y" : "n",
      withTradeDone: filterWithTradeDone ? "y" : "n",
    }).then((res) => {
      if (!res) return;
      setPosts(res.list);
    });
  }, [userDetail, filterWithTradeIng, filterWithTradeDone]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      {/* left */}
      <div
        style={{
          width: isMobile ? "100%" : "700px",
          height: "100%",
          position: "inherit",
          padding: "20px 60px",
          overflowY: "auto",
        }}
      >
        <h2 style={{ marginBottom: "10px" }}>직원 목록</h2>

        <div
          className="flex-row-between-center"
          style={{ width: "100%", flexWrap: "wrap" }}
        >
          {employeeList.map((user, i) => {
            return (
              <div
                key={i}
                className="employee-container"
                onClick={() => {
                  setSelectedUserId(user.id);
                }}
              >
                {/* 프로필,이름 */}
                <div
                  className="flex-row-start-center"
                  style={{
                    borderBottom: "1px solid var(--border-gray)",
                    paddingBottom: "10px",
                  }}
                >
                  <img
                    src={user.profileImgUrl || profileDefault}
                    style={{
                      width: "36px",
                      height: "36px",
                      objectFit: "cover",
                      marginRight: "14px",
                    }}
                  />
                  <div>
                    <p
                      className="text-small bold"
                      style={{ marginBottom: "7px" }}
                    >
                      {user.name}
                    </p>
                    <p
                      className="text-small"
                      style={{ color: "var(--border-gray)" }}
                    >
                      {USER_LEVEL_TO_LABEL[user.level]}
                    </p>
                  </div>
                </div>
                {/* 연락처,매물수 */}
                <div style={{ padding: "17px 0" }}>
                  <div
                    className="flex-row-between-center"
                    style={{ marginBottom: "4px" }}
                  >
                    <p
                      className="text-small"
                      style={{ color: "var(--border-gray)" }}
                    >
                      연락처
                    </p>
                    <p className="text-small bold">{user.phone}</p>
                  </div>
                  <div className="flex-row-between-center">
                    <p
                      className="text-small"
                      style={{ color: "var(--border-gray)" }}
                    >
                      등록 매물
                    </p>
                    <p className="text-small bold">{user.postCount}건</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {!isMobile && (
        <div
          style={{
            width: "10px",
            height: "100%",
            borderLeft: "1px solid var(--border-gray)",
            borderRight: "1px solid var(--border-gray)",
          }}
        />
      )}
      {/* right */}
      {!userDetail ? null : (
        <div
          style={
            isMobile
              ? {
                  backgroundColor: "#fff",
                  width: "100%",
                  padding: "38px 24px 0",
                  height: "100%",
                  overflowY: "auto",
                  position: "absolute",
                }
              : {
                  flex: 1,
                  padding: "10px 60px",
                  height: "100%",
                  overflowY: "auto",
                }
          }
        >
          <h2 style={{ marginBottom: "10px" }}>상세 페이지</h2>

          {/* 프로필사진, 인적사항 */}
          <div
            className="flex-row-start-center"
            style={{
              marginBottom: isMobile ? "32px" : "70px",
            }}
          >
            <img
              src={userDetail.profileImgUrl || profileDefault}
              style={{
                width: isMobile ? "80px" : "160px",
                height: isMobile ? "80px" : "160px",
                borderRadius: "160px",
                border: "1px solid var(--yellow-light)",
                marginRight: isMobile ? 0 : "60px",
              }}
            />

            <div>
              {isMobile ? (
                <div className="flex-col">
                  <div
                    className="flex-row-start-center"
                    style={{ marginBottom: "10px" }}
                  >
                    <p
                      className="text-small"
                      style={{ width: "60px", textAlign: "center" }}
                    >
                      이름
                    </p>
                    <p className="text-small bold">{userDetail.name}</p>
                  </div>
                  <div
                    className="flex-row-start-center"
                    style={{ marginBottom: "10px" }}
                  >
                    <p
                      className="text-small"
                      style={{ width: "60px", textAlign: "center" }}
                    >
                      유형
                    </p>
                    <p className="text-small bold">
                      {USER_LEVEL_TO_LABEL[userDetail.level]}
                    </p>
                  </div>
                  <div
                    className="flex-row-start-center"
                    style={{ marginBottom: "10px" }}
                  >
                    <p
                      className="text-small"
                      style={{ width: "60px", textAlign: "center" }}
                    >
                      연락처
                    </p>
                    <p className="text-small bold">{userDetail.phone}</p>
                  </div>
                  <div className="flex-row-start-center">
                    <p
                      className="text-small"
                      style={{ width: "60px", textAlign: "center" }}
                    >
                      가입일
                    </p>
                    <p className="text-small bold">
                      {printFormattedDate(new Date(userDetail.createdAt))}
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="flex-row-start-center"
                    style={{ marginBottom: "40px" }}
                  >
                    <div className="flex-row-start-center">
                      <p className="text-medium" style={{ width: "100px" }}>
                        이름
                      </p>
                      <p className="text-big2 bold" style={{ width: "240px" }}>
                        {userDetail.name}
                      </p>
                    </div>
                    <div className="flex-row-start-center">
                      <p className="text-medium" style={{ width: "100px" }}>
                        연락처
                      </p>
                      <p className="text-big2 bold" style={{ width: "240px" }}>
                        {userDetail.phone}
                      </p>
                    </div>
                  </div>

                  <div className="flex-row-start-center">
                    <div className="flex-row-start-center">
                      <p className="text-medium" style={{ width: "100px" }}>
                        유형
                      </p>
                      <p className="text-big2 bold" style={{ width: "240px" }}>
                        {USER_LEVEL_TO_LABEL[userDetail.level]}
                      </p>
                    </div>
                    <div className="flex-row-start-center">
                      <p className="text-medium" style={{ width: "100px" }}>
                        가입일
                      </p>
                      <p className="text-big2 bold" style={{ width: "240px" }}>
                        {printFormattedDate(new Date(userDetail.createdAt))}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* 등록매물 */}
          <div
            className="flex-row-between-center"
            style={{ marginBottom: "20px" }}
          >
            <h2>등록 매물</h2>

            {/* 필터 버튼 */}
            <div
              className="flex-row"
              style={{
                width: "160px",
                height: "32px",
                borderRadius: "8px",
                overflow: "hidden",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <span
                className="flex-row-center-center"
                style={{
                  flex: 1,
                  height: "100%",
                  color: "#fff",
                  backgroundColor: filterWithTradeIng
                    ? "var(--yellow-dark)"
                    : "var(--border-gray)",
                }}
                onClick={() => {
                  setFilterWithTradeIng((prev) => !prev);
                }}
              >
                판매중
              </span>
              <span
                className="flex-row-center-center"
                style={{
                  flex: 1,
                  height: "100%",
                  color: "#fff",
                  backgroundColor: filterWithTradeDone
                    ? "var(--yellow-dark)"
                    : "var(--border-gray)",
                }}
                onClick={() => {
                  setFilterWithTradeDone((prev) => !prev);
                }}
              >
                판매완료
              </span>
              <span
                style={{
                  width: "1px",
                  height: "24px",
                  backgroundColor: "var(--yellow-light)",
                  position: "absolute",
                  left: "79.5px",
                  top: "4px",
                }}
              ></span>
            </div>
          </div>

          {/* 목록 */}
          <div
            className="flex-row-start-center"
            style={{ gap: "20px", flexWrap: "wrap" }}
          >
            {posts.map((p, i) => {
              return (
                <PostListItemWithStatus
                  key={i}
                  post={p}
                  style={{
                    marginBottom: "20px",
                    width: isMobile ? "100%" : "430px",
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default EmployeePage;

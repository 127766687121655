import { useLocation, useNavigate } from "react-router-dom";
import BaseButton from "../../components/Button/Base";
import useIsMobile from "../../lib/hooks/useIsMobile";
import { useEffect, useMemo, useState } from "react";
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";
import BaseButtonOutline from "../../components/Button/Outline";
import { regNumber } from "../../lib/validator";
import { printCost } from "../../lib/util";

const PostStep3Page = () => {
  const prevState = useLocation().state;
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [saleKind, setSaleKind] = useState("매매");
  const [costOnce, setCostOnce] = useState("");
  const [costMonth, setCostMonth] = useState("");
  const [enterableDate, setEnterableDate] = useState("");
  const [introduceTitle, setIntroduceTitle] = useState("");
  const [introduceContent, setIntroduceContent] = useState("");
  const [mortgage, setMortgage] = useState("없음");
  const [canInsurance, setCanInsurance] = useState(false);

  const [canNext, setCanNext] = useState(false);

  useEffect(() => {
    const commonCondition =
      !!costOnce && !!enterableDate && !!introduceTitle && !!introduceContent;
    // 매매, 전세
    if (saleKind !== "월세") return setCanNext(commonCondition);
    // 월세
    setCanNext(commonCondition && !!costMonth);
  }, [
    saleKind,
    costOnce,
    costMonth,
    enterableDate,
    introduceTitle,
    introduceContent,
  ]);

  const saleKindItems = useMemo(() => {
    return [
      { label: "매매", value: "매매" },
      { label: "전세", value: "전세" },
      { label: "월세", value: "월세" },
    ];
  }, []);

  return (
    <div
      className="post-register-page-wrapper"
      style={
        isMobile
          ? {
              paddingLeft: "20px",
              paddingRight: "20px",
            }
          : {}
      }
    >
      {!isMobile && (
        <h2 style={{ marginBottom: "40px" }}>매물 등록 요청하기</h2>
      )}
      <div
        className="flex-col-center-center"
        style={{ width: "344px", flex: 1, alignSelf: "center" }}
      >
        <div style={{ width: "100%" }}>
          <label>거래 유형</label>
          <Select
            items={saleKindItems}
            value={saleKind}
            onChange={(v) => {
              setSaleKind(v);
            }}
            style={{ marginBottom: "20px" }}
          />

          <div className="flex-row-between-center" style={{ gap: "20px" }}>
            <div style={{ flex: 1 }}>
              <div
                className="flex-row-between-center"
                style={{ marginBottom: "10px" }}
              >
                <label style={{ marginBottom: 0 }}>
                  {saleKind === "월세" ? "월세 보증금" : saleKind + " 가격"}{" "}
                  (만원)
                </label>
                <p className="alert-desc" style={{ color: "brown" }}>
                  {Number(costOnce)
                    ? printCost(Number(costOnce), { manwonLabel: "만" })
                    : ""}
                </p>
              </div>
              <input
                type="number"
                step={1}
                placeholder="숫자만 입력 가능"
                maxLength={10}
                style={{ marginBottom: "20px" }}
                value={costOnce}
                onChange={(e) => {
                  if (e.target.value.length && !regNumber.test(e.target.value))
                    return;
                  setCostOnce(e.target.value);
                }}
              />
            </div>

            {saleKind === "월세" && (
              <div style={{ flex: 1 }}>
                <label>월세 (만원)</label>
                <input
                  type="number"
                  step={1}
                  placeholder="숫자만 입력 가능"
                  maxLength={10}
                  style={{ marginBottom: "20px" }}
                  value={costMonth}
                  onChange={(e) => {
                    if (
                      e.target.value.length &&
                      !regNumber.test(e.target.value)
                    )
                      return;
                    setCostMonth(e.target.value);
                  }}
                />
              </div>
            )}
          </div>

          <label>입주가능일</label>
          <input
            type="date"
            value={enterableDate}
            onChange={(e) => setEnterableDate(e.target.value)}
            style={{ marginBottom: "20px" }}
          />

          <label>제목</label>
          <input
            type="text"
            placeholder="매물 소개글 제목 입력 (최대 30자)"
            value={introduceTitle}
            onChange={(e) => setIntroduceTitle(e.target.value)}
            style={{ marginBottom: "20px" }}
          />

          <label>내용</label>
          <textarea
            placeholder="매물 소개글 내용 입력 (최대 100자)"
            value={introduceContent}
            onChange={(e) => setIntroduceContent(e.target.value)}
            style={{ marginBottom: "20px" }}
          />

          <label>융자</label>
          <div
            className="flex-row-between-center"
            style={{ gap: "20px", marginBottom: "20px" }}
          >
            <Checkbox
              label="없음"
              checked={mortgage === "없음"}
              onClick={() => setMortgage("없음")}
              style={{ flex: 1 }}
            />
            <Checkbox
              label="30% 미만"
              checked={mortgage === "30% 미만"}
              onClick={() => setMortgage("30% 미만")}
              style={{ flex: 1 }}
            />
            <Checkbox
              label="30% 이상"
              checked={mortgage === "30% 이상"}
              onClick={() => setMortgage("30% 이상")}
              style={{ flex: 1 }}
            />
          </div>

          <label>보증보험 가능유무</label>
          <Checkbox
            label="보증보험 가능함"
            checked={canInsurance}
            onClick={() => setCanInsurance((prev) => !prev)}
          />
        </div>

        <div
          className="flex-row-between-center"
          style={{ gap: "20px", marginTop: "100px", width: "100%" }}
        >
          <BaseButtonOutline
            text="이전"
            onClick={() => navigate(-1)}
            style={{ flex: 1, fontSize: "16px" }}
          />
          <BaseButton
            text="다음 (3/4)"
            disabled={!canNext}
            onClick={() => {
              navigate("/post/step4", {
                state: {
                  ...(prevState ?? {}),
                  saleKind,
                  costOnce: Number(costOnce),
                  costMonth: Number(costMonth) || null,
                  enterableDate,
                  introduceTitle,
                  introduceContent,
                  mortgage,
                  canInsurance,
                },
              });
            }}
            style={{ flex: 1, fontWeight: 400, fontSize: "16px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default PostStep3Page;

export const regNumber = new RegExp(/^[0-9]+$/);

// uid
export const regUid = new RegExp(/^[0-9a-zA-Z]{4,20}$/);
export const validateUid = (uid: string) => {
  if (uid.length < 4 || uid.length > 20) return "fail:length";
  if (!regUid.test(uid)) return "fail:format";

  return "pass";
};

// pw
export const validatePw = (pw: string) => {
  if (pw.length < 4 || pw.length > 20) return "fail:length";

  let cntNumber = 0;
  let cntAlphabet = 0;
  let cntEtc = 0;
  for (const letter of pw) {
    if (letter >= "0" && letter <= "9") cntNumber++;
    else if (
      (letter >= "a" && letter <= "z") ||
      (letter >= "A" && letter <= "Z")
    )
      cntAlphabet++;
    else cntEtc++;
  }
  if (!cntAlphabet || !cntEtc) return "fail:format";

  return "pass";
};

// phone
export const regPhone = new RegExp(/^010-[0-9]{4}-[0-9]{4}$/);

// auth code
export const regAuthCode = new RegExp(/^[0-9]{4}$/);

export const regEmail = new RegExp(
  /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
);

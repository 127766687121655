/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from "react";
import iconHeadphone from "../assets/headphone.png";
import CloseCircleButton from "../components/Button/CloseCircle";
import { TMapBound, TMapFilter, TMarkerApartInfo } from "../lib/types/map.type";
import CustomMarker from "../components/Map/Marker";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atom__globalPostFilter,
  atom__me,
  atom__socket,
} from "../lib/recoil/common.atom";
import useIsMobile from "../lib/hooks/useIsMobile";
import GlobalPostFilter from "../components/GNB/GlobalPostFilter";
import {
  api_apart_month_stat_cost,
  api_apartDetailOnMap,
  api_banners,
  api_listOnMap,
  api_search_apart_avg_cost,
} from "../api/apart";
import {
  getOS,
  insertComma,
  KAKAO_LINK,
  printCost,
  printCostPreferUk,
  printFormattedDateKr,
  기다려,
} from "../lib/util";
import { TPost } from "../lib/types/post.type";
import {
  api_postDetail,
  api_postListOnMap,
  api_postTradeDone,
} from "../api/post";
import iconClose from "../assets/cancel.png";
import iconCloseWhite from "../assets/cancel_white.png";
import iconArrowDouble from "../assets/arrow-right-double.png";
import iconArrowDoubleGray from "../assets/arrow-right-double-gray.png";
import iconMedalGold from "../assets/medal_gold.png";
import iconMedalBronze from "../assets/medal_bronze.png";
import iconMedalSilver from "../assets/medal_silver.png";
import iconArrowLeftBackground from "../assets/arrow-left-background.png";
import iconArrowRightBackground from "../assets/arrow-right-background.png";
import profileDefaultLong from "../assets/profile-default-long.png";
import SmallButton from "../components/Button/Small";
import SmallButtonOutline from "../components/Button/SmallOutline";
import AreaTypeBox from "../components/Post/AreaTypeBox";
import BaseButtonOutline from "../components/Button/Outline";
import BaseButton from "../components/Button/Base";
import {
  atom__closePicture,
  atom__notificationModalInfo,
  atom__successModalInfo,
  atom__userDetailModalInfo,
} from "../lib/recoil/modal.atom";
import PostDetail from "../components/Post/PostDetail";
import { TApartDetailOnMap } from "../lib/types/apart.type";
import SelectSmall from "../components/SelectSmall";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ModalNotification from "../components/Modal/Notification";
import { BASE_URL } from "../api/axios";
import { io } from "socket.io-client";
import { socket } from "../App";
import Slider from "react-slick";

import defaultHomeImage from "../assets/default-home.png";
import defaultHomeImage1 from "../assets/default-home-1.png";
import defaultHomeImage2 from "../assets/default-home-2.png";
import LineChart from "../components/LineChart";

const CustomLeftArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button
      onClick={() => onClick(currentSlide)}
      style={{
        position: "absolute",
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "transparent",
      }}
    >
      <img
        src={iconArrowLeftBackground}
        style={{ width: "22px", height: "22px" }}
      />
    </button>
  );
};

const CustomRightArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <button
      onClick={() => onClick(currentSlide)}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "transparent",
      }}
    >
      <img
        src={iconArrowRightBackground}
        style={{ width: "22px", height: "22px" }}
      />
    </button>
  );
};

// ----------

const MapPage = () => {
  const isMobile = useIsMobile();

  const r__filter = useRecoilValue(atom__globalPostFilter);
  const r__me = useRecoilValue(atom__me);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setUserDetailModalInfo = useSetRecoilState(
    atom__userDetailModalInfo
  );
  const [r__notificationModalInfo, r__setNotificationModalInfo] =
    useRecoilState(atom__notificationModalInfo);

  const [visibleContact, setVisibleContact] = useState(false);

  const [mapBounds, setMapBounds] = useState<TMapBound>({
    // north east: 수원시청 좌표에서 y+0.01, x+0.001
    _ne: {
      y: 37.273416,
      x: 127.029609,
    },
    // north east: 수원시청 좌표에서 y-0.01, x-0.001
    _sw: {
      y: 37.253416,
      x: 127.027609,
    },
  });

  const [selectedApartId, setSelectedApartId] = useState("");
  // 선택한 아파트의 전체 매물
  const [posts, setPosts] = useState([]) as any;

  const [clickedApart, setClickedApart] = useState({}) as any;

  // 선택한 아파트의 전체 동
  const [dongs, setDongs] = useState<string[]>([]);
  // 전체 동 중 선택한 동
  const [selectedDongs, setSelectedDongs] = useState<string[]>([]);
  // 거래완료처리할 매물 고르는중인지
  const [isSelectingTradeDone, setIsSelectingTradeDone] = useState(false);
  // 거래완료처리할 매물 고른거
  const [selectedTradeDonePosts, setSelectedTradeDonePosts] = useState<
    { apartId: string; dong: string; ho: string }[]
  >([]);

  const [searchKind, setSearchKind] = useState("전세");

  const [selectedPostId, setSelectedPostId] = useState("");
  const [postDetail, setPostDetail] = useState<
    (TPost & { badgeWorth: number; wish: boolean }) | null
  >(null);

  const [apartDetail, setApartDetail] = useState<TApartDetailOnMap | null>(
    null
  );
  const [selectedTradePyeong, setSelectedTradePyeong] = useState("x");
  const [postImageUrls, setPostImageUrls] = useState<string[][]>([]);

  const [curentBannerNumber, setCurrentBannerNumber] = useState(0);
  const [bannerUrls, setBannerUrls] = useState([]);
  const [bannerLoading, setBannerLoading] = useState(false);

  const refMap = useRef<naver.maps.Map>();
  /** '지도 바운더리 변경' 이벤트 디바운싱 */
  const refDebounce1 = useRef<NodeJS.Timer | null>(null);

  const refBanner: any = useRef(null);

  // 마지막으로 조회한 필터값. _fetch()에서는 r__filter 값과 이 마지막 필터값을 비교해서, 기존 마커를 전부 지우고 다시 받아올건지 판단함.
  const refLastFilter = useRef<TMapFilter>({
    address2: "x",
    address3: "x",
    apartKind: "x",
    postKind: "x",
    houseCount: "x-x",
    enterAge: "x-x",
    amount: "x-x",
    size: "x-x",
  });
  const refFetchedApart = useRef<
    { apart: TMarkerApartInfo; marker: naver.maps.Marker }[]
  >([]);

  const refCarousel = useRef<Carousel>(null);

  const r__setatomClosePicture = useSetRecoilState(atom__closePicture);

  const [tradeMonthAvgCost, setTradeMonthAvgCost] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  useEffect(() => {
    if (!navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        const os = getOS();

        const ip = "211.123.891.90";
        socket.emit("acceptLocationPermission", {
          latitude,
          longitude,
          ip,
          os,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  /**
   *  r__setSuccessModalInfo({
      desc: ["매물 등록 요청이 정상적으로 접수되었습니다."],
      onClickBtn: () => {
        window.location.replace("/map");
      },
    });

   */

  // 아파트 마커 클릭 시(= selectedApartId에 값이 변경되면) 해당 아파트의 매물목록 표시
  useEffect(() => {
    closePostList();

    setApartDetail(null);
    setSelectedTradePyeong("x");
    setPostImageUrls([]);

    if (!selectedApartId) {
      return;
    }

    // 여기다 추가하자
    api_apart_month_stat_cost(selectedApartId).then((res: any) => {
      if (!res) {
        return;
      }

      setTradeMonthAvgCost(res.months);
    });

    // 비로그인 상태와 로그인 상태일 때 각각 다른 api
    if (!r__me || !r__me.activeMembershipId) {
      api_apartDetailOnMap(selectedApartId).then((res) => {
        if (!res) {
          return;
        }

        setApartDetail(res);
        setSelectedTradePyeong(Object.keys(res.avgPerPyeong)[0] ?? "x");

        if (res.postImageUrls.length > 0) {
          let slices: string[][] = [];
          for (let i = 0; i < res.postImageUrls.length; i += 3) {
            const piece = [res.postImageUrls[i]];
            if (res.postImageUrls[i + 1]) piece.push(res.postImageUrls[i + 1]);
            if (res.postImageUrls[i + 2]) piece.push(res.postImageUrls[i + 2]);
            slices.push(piece);
          }
          setPostImageUrls(slices);
        } else {
          setPostImageUrls([
            // [defaultHomeImage],
            // [defaultHomeImage1],
            // [defaultHomeImage2],
          ]);
        }
      });

      return;
    }

    _fetchPost(selectedApartId);
  }, [selectedApartId]);

  useEffect(() => {
    api_banners().then((res) => {
      if (!res) {
        return;
      }

      console.log("set.. banner ?");

      setBannerUrls(res.bannerUrls);
    });
  }, []);

  useEffect(() => {
    const mapDiv = document.getElementById("map");
    if (!mapDiv) return;
    refMap.current = new window.naver.maps.Map(mapDiv, {
      center: new naver.maps.LatLng(37.263416, 127.028609), // 초기좌표: 수원시청
      zoom: 15, // 유효한 줌레벨: 7~21
    });

    // '지도 바운더리 변경' 이벤트 핸들러
    // NOTE: 첫 렌더링 시에는 이 이벤트가 발생하지 않으므로, 처음엔 수원시청 좌표 중심으로 아파트 몇개만 fetch
    refMap.current?.addListener("bounds_changed", (bounds: TMapBound) => {
      if (refDebounce1.current !== null) {
        clearTimeout(refDebounce1.current);
      }
      refDebounce1.current = setTimeout(() => {
        refDebounce1.current = null;

        setMapBounds(bounds);
      }, 500);
    });
  }, []);

  // 필터 내용이나 맵 바운더리가 바뀔 때마다 fetch
  useEffect(() => {
    _fetchMarker();
  }, [r__filter, mapBounds]);

  useEffect(() => {
    // 거래완료요청 모드로 들어가면, 매물상세 띄워져있던건 제거
    if (isSelectingTradeDone) {
      setSelectedPostId("");
      setPostDetail(null);
      return;
    }

    // 거래완료요청 모드가 해제되면, 선택했던 동들 초기화
    setSelectedTradeDonePosts([]);
  }, [isSelectingTradeDone]);

  useEffect(() => {
    if (!selectedPostId) {
      setPostDetail(null);
      return;
    }

    api_postDetail(selectedPostId).then((res) => {
      if (!res) return;
      setPostDetail(res.post);
    });
  }, [selectedPostId]);

  /**
   * * Map 바운더리에 있는 매물 불러오는 함수
   * @returns
   */
  const _fetchMarker = async () => {
    // 필터 달라졌는지 검사 -> 달라졌으면 마커들 리셋
    let needReset = false;
    const filterEntriesAfter = Object.entries(r__filter) as [
      keyof TMapFilter,
      string
    ][];
    for (let i = 0; i < filterEntriesAfter.length; i++) {
      const [filterName, filterValue] = filterEntriesAfter[i];
      // NOTE: 유형필터(아파트/분양)는 아직 분양 안다루니 reset하지않음
      if (filterName === "apartKind") continue;

      if (refLastFilter.current[filterName] !== filterValue) {
        needReset = true;
        break;
      }
    }

    // 마지막조회한 필터 저장
    refLastFilter.current = r__filter;

    // 마커 리셋해야하는 경우: 모든 마커를 지도에서 지우고, 마커에 달린 이벤트리스너도 지우고, 마지막으로 refFetchedApart를 비움
    if (needReset) {
      refFetchedApart.current.forEach(({ apart, marker }) => {
        marker.clearListeners("click");
        marker.setMap(null);
      });
      refFetchedApart.current = [];
    }

    const res = await api_listOnMap({
      district3Id: r__filter.address2,
      district4Id: r__filter.address3,
      postKind: r__filter.postKind as "x" | "매매" | "전월세",
      houseCount: r__filter.houseCount,
      enterAge: r__filter.enterAge,
      amount: r__filter.amount,
      size: r__filter.size,
      ne: `${mapBounds._ne.y},${mapBounds._ne.x}`,
      sw: `${mapBounds._sw.y},${mapBounds._sw.y}`,
      excludeIds: refFetchedApart.current.map((a) => a.apart.id),
    });

    if (!res) return;

    // 응답받은 새 아파트들을 마커생성 후 캐싱(=refFetchedApart에 추가)
    const apartAndMarker = res.list.map((item: any) => {
      const marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(item.lat, item.lng),
        map: refMap.current,
        icon: {
          content: CustomMarker({
            name: item.kaptName,
            postCount: item.cnt > 99 ? "99+" : item.cnt + "",
          }),
          size: new naver.maps.Size(100, 117),
        },
      });
      marker.addListener("click", () => {
        setSelectedApartId(item.id);
      });

      return {
        apart: item,
        marker,
      };
    });

    refFetchedApart.current.push(...apartAndMarker);
  };

  const _fetchPost = (apartId: string) => {
    // 여기야
    api_postListOnMap(apartId).then((res: any) => {
      if (!res) return;

      if (res.posts.length > 0) {
        // 정렬해서 setPosts
        const sortedPosts = res.posts.sort((a, b) => {
          if (!a.office && !b.office) return 0;

          const obA = a.office?.badges?.find(
            (ob) => ob.apartId === apartId && ob.status === "accept"
          );
          const obB = a.office?.badges?.find(
            (ob) => ob.apartId === apartId && ob.status === "accept"
          );

          const worthA = obA?.badge.worth ?? 0;
          const worthB = obA?.badge.worth ?? 0;
          if (worthA === 0 && worthB === 0) return 0;
          if (worthA !== worthB) return worthA > worthB ? -1 : 1;

          // 같은 메달이면 시작일 기준
          const startA = obA?.startDate ?? "-";
          const startB = obB?.startDate ?? "-";
          if (startA === "-" && startB === "-") return 0;
          if (startA !== startB) return startA < startB ? -1 : 1;

          // 시작일 같으면 이름순
          const officeA = a?.office.name ?? "-";
          const officeB = b?.office.name ?? "-";
          return officeA < officeB ? -1 : 1;
        });

        setPosts(sortedPosts);

        setDongs(
          Object.keys(
            res.posts.reduce((acc: Record<string, boolean>, cur) => {
              acc[cur.dong] = true;
              return acc;
            }, {})
          ).sort((a, b) => (a > b ? 1 : -1))
        );
        setSelectedDongs([]);
      } else {
        setClickedApart(res.apartRow);
      }
    });
  };

  const requestTradeDone = async () => {
    if (!selectedTradeDonePosts.length) return;

    const res = await Promise.all(
      selectedTradeDonePosts.map((p) => api_postTradeDone(p))
    );
    if (!res) return;

    r__setSuccessModalInfo({
      title: "요청 접수",
      desc: [
        "거래완료처리 요청이 접수되었습니다.",
        "관리자 확인 후 최종 완료처리 예정입니다.",
      ],
    });
    setIsSelectingTradeDone(false);
    _fetchPost(selectedApartId);
  };

  const closePostList = () => {
    setPosts([]);
    setDongs([]);
    setSelectedDongs([]);
    setSelectedPostId("");
    setPostDetail(null);
    setIsSelectingTradeDone(false);
    setSelectedTradeDonePosts([]);
  };

  useEffect(() => {
    (async () => {
      if (curentBannerNumber > 0) {
        setBannerLoading(true);

        await 기다려(200);

        setBannerUrls((prev) => {
          const clonePrev = [...prev];

          const bannerFront = [];
          const bannerBack = [];
          const bannerLastBack = [];

          clonePrev.forEach((banner, index) => {
            if (curentBannerNumber === index) {
              bannerFront.push(banner);
            } else if (curentBannerNumber > index) {
              bannerLastBack.push(banner);
            } else {
              bannerBack.push(banner);
            }
          });

          return [...bannerFront, ...bannerBack, ...bannerLastBack];
        });

        setBannerLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApartId]);

  return (
    <div
      className="flex-col"
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      {!!r__me && !!r__me.activeMembershipId && (
        <div style={{ position: "relative" }}>
          <GlobalPostFilter
            data-label="상단필터"
            onChangeAddress={(lat, lng, zoom) => {
              if (!refMap.current) return;
              refMap.current.setCenter(new naver.maps.LatLng(lat, lng));
              refMap.current.setZoom(zoom);
            }}
          />

          {/* 알림 팝업 */}
          {!!r__notificationModalInfo && <ModalNotification />}
        </div>
      )}

      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          id="map"
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>

        {/* (비로그인) 마커 클릭 시 표시되는 아파트정보 */}
        {!!apartDetail && (
          <div className="map-postlist-container">
            <div
              style={
                isMobile
                  ? {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: window.innerWidth,
                      borderRadius: 0,
                      padding: 0,
                    }
                  : { padding: 0, width: "450px" }
              }
            >
              {/* 노란색 영역 (아파트명, 주소) */}
              <div
                style={{
                  padding: "23px",
                  backgroundColor: "var(--yellow-dark)",
                }}
              >
                <p
                  style={{
                    fontSize: "32px",
                    lineHeight: "38.19px",
                    textAlign: "center",
                    color: "var(--brown)",
                    marginBottom: "21px",
                  }}
                >
                  {apartDetail.apart.name}
                </p>
                <p
                  className="text-medium"
                  style={{ color: "var(--beige)", textAlign: "center" }}
                >
                  {apartDetail.apart.address}
                </p>
              </div>

              {/* 세대수, 사용승인일, 주차대수, 층수 */}
              <div
                className="flex-row-start-center"
                style={{
                  padding: "18px 0",
                  color: "var(--brown)",
                  textAlign: "center",
                }}
              >
                <p className="text-small bold" style={{ flex: 1 }}>
                  {insertComma(apartDetail.apart.cntHouse)}세대
                </p>
                <p className="text-small bold" style={{ flex: 1 }}>
                  {printFormattedDateKr(new Date(apartDetail.apart.useDate), {
                    noDate: true,
                  })}
                </p>
                <div style={{ flex: 1 }}>
                  <p className="text-small bold">총 주차대수</p>
                  <p className="text-small bold">
                    {insertComma(apartDetail.apart.cntParking)}대
                  </p>
                </div>
                <p className="text-small bold" style={{ flex: 1 }}>
                  {apartDetail.apart.topFloor}층
                </p>
              </div>

              {postImageUrls.length === 0 && (
                <div
                  style={{
                    padding: "20px 40px 0 20px",
                  }}
                >
                  <p
                    className="text-small bold"
                    style={{ marginBottom: "10px" }}
                  >
                    해당 단지의 사진이 없습니다
                  </p>
                </div>
              )}

              {/* 매물사진 */}
              {postImageUrls.length > 0 && (
                <div style={isMobile ? {} : { padding: "8px 18px" }}>
                  <Carousel
                    ref={refCarousel}
                    responsive={{
                      desktop: {
                        breakpoint: { min: 1081, max: 4000 },
                        items: 1,
                      },
                      mobile: {
                        breakpoint: { min: 0, max: 1080 },
                        items: 1,
                      },
                    }}
                    deviceType={isMobile ? "mobile" : "desktop"}
                    swipeable={true} // 스와이프 가능하도록 설정
                    draggable={true} // 드래그 가능하도록 설정
                    showDots={true}
                    infinite={true}
                    autoPlay={false}
                    dotListClass="custom-dot-list"
                    itemClass="zzzzzz"
                    customLeftArrow={
                      <CustomLeftArrow
                        onClick={(currentSlide: number) => {
                          if (!refCarousel.current) return;
                          refCarousel.current.goToSlide(currentSlide - 1);
                        }}
                      />
                    }
                    customRightArrow={
                      <CustomRightArrow
                        onClick={(currentSlide: number) => {
                          if (!refCarousel.current) return;
                          refCarousel.current.goToSlide(currentSlide + 1);
                        }}
                      />
                    }
                  >
                    {postImageUrls.map((slice, i) => {
                      return (
                        <div
                          key={`post-images-url-${i}`}
                          style={{}}
                          className={"flex-row-between-center"}
                        >
                          {slice.map((url, j) => {
                            return (
                              <img
                                onClick={(event) => {
                                  r__setatomClosePicture({
                                    show: true,
                                    image: url,
                                  });
                                }}
                                key={`post-images-slice-${j}`}
                                src={url}
                                style={{
                                  width: isMobile ? window.innerWidth : "100%",
                                  objectFit: "cover",
                                }}
                              />
                            );
                          })}
                          <br />
                          {Array.from({ length: 3 - slice.length }, (x, i) => (
                            <div
                              key={`dot-${i}`}
                              style={{
                                width: "120px",
                                height: "120px",
                              }}
                            />
                          ))}
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              )}

              {/* 전월세평균가 */}
              <div>
                <div
                  className="flex-row-between-center"
                  style={{ padding: "16px 0 16px 24px" }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    element-label="필터"
                  >
                    <SelectSmall
                      items={
                        Object.entries(apartDetail.avgPerPyeong).length === 0
                          ? [{ label: "평수", value: "x" }]
                          : Object.keys(apartDetail.avgPerPyeong).map(
                              (pyeong) => ({
                                label: pyeong + "평",
                                value: pyeong,
                              })
                            )
                      }
                      value={selectedTradePyeong}
                      onChange={async (v: string) => {
                        const excluUseAr =
                          (apartDetail.pyeongs as any)[v] ?? "x";

                        const tradeRow = await api_search_apart_avg_cost(
                          selectedApartId,
                          searchKind,
                          excluUseAr
                        );

                        const monthStat = await api_apart_month_stat_cost(
                          selectedApartId,
                          searchKind,
                          excluUseAr
                        );

                        setTradeMonthAvgCost(monthStat.months);
                        setApartDetail((prev: any) => {
                          return {
                            ...prev,
                            tradeAvg: tradeRow.avg,
                          };
                        });

                        setSelectedTradePyeong(v);
                      }}
                    />

                    <SelectSmall
                      items={apartDetail.categories.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      style={{ marginLeft: 16 }}
                      value={searchKind}
                      onChange={async (v: string) => {
                        const excluUseAr =
                          (apartDetail.pyeongs as any)[selectedTradePyeong] ??
                          "x";

                        const tradeRow = await api_search_apart_avg_cost(
                          selectedApartId,
                          v,
                          excluUseAr
                        );

                        const monthStat = await api_apart_month_stat_cost(
                          selectedApartId,
                          searchKind,
                          excluUseAr
                        );

                        setTradeMonthAvgCost(monthStat.months);

                        setApartDetail((prev: any) => {
                          return {
                            ...prev,
                            tradeAvg: tradeRow.avg,
                          };
                        });

                        setSearchKind(v);
                      }}
                    />
                  </div>

                  <span
                    className="text-small bold"
                    style={{
                      width: "60px",
                      padding: "7px 0",
                      borderTopLeftRadius: "20px",
                      borderBottomLeftRadius: "20px",
                      backgroundColor: "var(--brown)",

                      textAlign: "center",
                      color: "var(--yellow-light)",
                    }}
                  >
                    {searchKind}
                  </span>
                </div>
                <div
                  style={{
                    paddingBottom: "13px",
                    paddingRight: "60px",
                    textAlign: "right",
                  }}
                >
                  <p
                    className="text-small"
                    style={{ color: "var(--brown)", marginBottom: "6px" }}
                  >
                    최근 1년의 {searchKind} 실거래 평균
                  </p>
                  <p className="text-big2 bold">
                    {selectedTradePyeong === "x" || !apartDetail.tradeAvg
                      ? "실거래 내역이 없습니다."
                      : printCost(apartDetail.tradeAvg)}
                  </p>
                </div>
              </div>

              <LineChart data={tradeMonthAvgCost} style={{ marginTop: 18 }} />

              {/* 배너[여기야] */}
              {bannerLoading ? (
                <div>배너 로딩중...</div>
              ) : bannerUrls.length > 0 ? (
                <Carousel
                  responsive={{
                    desktop: {
                      breakpoint: { min: 1081, max: 4000 },
                      items: 1,
                    },
                    mobile: {
                      breakpoint: { min: 0, max: 1080 },
                      items: 1,
                    },
                  }}
                  deviceType={isMobile ? "mobile" : "desktop"}
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={6000}
                  beforeChange={(n) => {}}
                  afterChange={(n) => {
                    setCurrentBannerNumber(n - 1);
                  }}
                  customLeftArrow={<span />}
                  customRightArrow={<span />}
                >
                  {bannerUrls.map((bannerUrl: any, i) => (
                    <a
                      key={`banner-${i}`}
                      href={bannerUrl.link ? bannerUrl.link : "#"}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        width: "100%",
                        aspectRatio: 450 / 300,
                      }}
                    >
                      <img
                        src={bannerUrl.url}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                      />
                    </a>
                  ))}
                </Carousel>
              ) : (
                <div
                  style={{
                    padding: "20px 40px 0 20px",
                  }}
                >
                  <p
                    className="text-small bold"
                    style={{ marginBottom: "10px" }}
                  >
                    현재 게시된 광고가 없습니다
                  </p>
                </div>
              )}

              <div
                style={{
                  padding: "20px 40px 0 20px",
                }}
              >
                {apartDetail.proUsers && apartDetail.proUsers.length > 0 ? (
                  apartDetail.proUsers.map((user, i) => {
                    return (
                      <>
                        <p
                          className="text-small bold"
                          style={{ marginBottom: "10px" }}
                        >
                          이 단지 전문 중개사
                        </p>
                        <div
                          key={`apart-detail-proUser-${user.id}`}
                          className="flex-row-between-center"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            r__setUserDetailModalInfo({
                              userId: user.id,
                              apartId: selectedApartId,
                            });
                          }}
                        >
                          <div
                            className="flex-row"
                            style={{
                              alignItems: "flex-end",
                            }}
                          >
                            <div className="flex-row-start-center">
                              <img
                                src={iconArrowDoubleGray}
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  marginRight: "12px",
                                }}
                              />
                              <p className="text-big2 bold">{user.name}</p>
                            </div>
                            <p
                              className="text-small bold"
                              style={{ marginLeft: "4px" }}
                            >
                              공인중개사
                            </p>
                          </div>
                          <div className="image-circle-wrap">
                            <img
                              src={user.profileImgUrl || profileDefaultLong}
                              style={{
                                width: "140px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div>
                    <p
                      className="text-small bold"
                      style={{ marginBottom: "10px" }}
                    >
                      현재 지정된 전문 중개사가 없습니다
                    </p>
                  </div>
                )}
              </div>

              {/* 닫기 버튼 */}
              <span
                style={{
                  padding: "4px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                }}
                onClick={() => setSelectedApartId("")}
              >
                <img
                  src={iconCloseWhite}
                  style={{ width: "28px", height: "28px" }}
                />
              </span>
            </div>
          </div>
        )}

        {posts.length === 0 && Object.keys(clickedApart).length > 0 && (
          <div
            className="map-postlist-container"
            style={isMobile ? { padding: 0 } : {}}
          >
            {/* 매물목록 */}
            <div
              style={
                isMobile
                  ? {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: window.innerWidth,
                      borderRadius: 0,
                    }
                  : {}
              }
            >
              <div style={{ padding: "0 20px" }}>
                <div
                  className="flex-row-center-center"
                  style={{ flexDirection: "column" }}
                >
                  <div
                    style={{
                      padding: "20px 0",
                      marginBottom: "20px",
                      marginTop: 20,
                      borderBottom: "1px solid var(--border-gray)",
                    }}
                  >
                    <h2 style={{ textAlign: "center" }}>
                      {clickedApart.kaptName}
                    </h2>
                  </div>

                  <div>
                    <h4>등록된 매물이 없습니다</h4>
                  </div>
                </div>

                {/* 닫기 버튼 */}
                <span
                  style={{
                    padding: "4px",
                    cursor: "pointer",
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                  }}
                  onClick={() => setClickedApart({})}
                >
                  <img
                    src={iconClose}
                    style={{ width: "28px", height: "28px" }}
                  />
                </span>
              </div>
            </div>
          </div>
        )}

        {/* 마커 클릭 시 표시되는 매물 레이어 */}
        {posts.length > 0 && (
          <div
            className="map-postlist-container"
            style={isMobile ? { padding: 0 } : {}}
          >
            {/* 매물목록 */}
            <div
              style={
                isMobile
                  ? {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: window.innerWidth,
                      borderRadius: 0,
                    }
                  : {}
              }
            >
              <div style={{ padding: "0 20px" }}>
                <p className="text-small bold">등록된 매물</p>

                {/* n건 + 구분선 */}
                <div className="flex-row-center-center">
                  <div
                    style={{
                      padding: "20px 0",
                      marginBottom: "20px",
                      borderBottom: "1px solid var(--border-gray)",
                    }}
                  >
                    <h3 style={{ marginBottom: "10px", textAlign: "center" }}>
                      {posts[0]?.apart.kaptName ?? ""}
                    </h3>
                    <h2 style={{ textAlign: "center" }}>{posts.length}건</h2>
                  </div>
                </div>

                {/* 동 목록 */}
                <div
                  className="flex-row"
                  style={{
                    flexWrap: "wrap",
                    gap: "14px",
                    marginBottom: "10px",
                  }}
                >
                  {dongs.map((dong) => {
                    const selected = selectedDongs.includes(dong);
                    return selected ? (
                      <SmallButton
                        key={dong}
                        style={{ marginBottom: "20px" }}
                        text={dong + "동"}
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          // 선택해제
                          setSelectedDongs((prev) => {
                            const copied = prev.slice();
                            const idx = copied.findIndex((d) => d === dong);
                            copied.splice(idx, 1);
                            return copied;
                          });
                        }}
                      />
                    ) : (
                      <SmallButtonOutline
                        key={dong}
                        style={{ marginBottom: "20px" }}
                        text={dong + "동"}
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          // 선택
                          setSelectedDongs((prev) => [...prev, dong]);
                        }}
                      />
                    );
                  })}
                </div>

                {/* 거래완료 요청 안내 */}
                <div
                  className="flex-row"
                  style={{ justifyContent: "flex-end", marginBottom: "40px" }}
                >
                  <p
                    className="text-small"
                    style={{
                      color: isSelectingTradeDone
                        ? "var(--yellow-dark)"
                        : "var(--brown)",
                      cursor: "pointer",
                      padding: "4px",
                      fontWeight: isSelectingTradeDone ? 700 : 400,
                    }}
                    onClick={() => setIsSelectingTradeDone((prev) => !prev)}
                  >
                    {isSelectingTradeDone
                      ? "거래완료된 매물을 선택한 후 아래의 요청 버튼을 눌러주세요."
                      : "거래완료된 매물이 있나요?"}
                  </p>
                </div>
              </div>

              {/* 매물목록 */}
              <div>
                {posts
                  .filter((p) => selectedDongs.includes(p.dong))
                  .map((post) => {
                    const badgeWorth =
                      post.office?.badges.find(
                        (ob) =>
                          ob.apartId === selectedApartId &&
                          ob.status === "accept"
                      )?.badge.worth ?? 0;
                    let creatorName = post.ownerName ? "소유주 등록매물" : "";
                    if (post.creatorUser) {
                      if (post.creatorUser.level < 70)
                        creatorName = post.office.name;
                      else
                        creatorName =
                          post.creatorUser.name +
                          (post.creatorUser.level === 100
                            ? " 개업공인중개사"
                            : " 소속공인중개사");
                    } else if (post.office) creatorName = post.office.name;
                    return (
                      <div
                        key={post.id}
                        onClick={() => {
                          if (isSelectingTradeDone) {
                            setSelectedTradeDonePosts((prev) => {
                              const selectedIdx = prev.findIndex(
                                (p) => p.dong === post.dong && p.ho === post.ho
                              );
                              // 추가
                              if (selectedIdx === -1)
                                return [
                                  ...prev,
                                  {
                                    apartId: selectedApartId,
                                    dong: post.dong,
                                    ho: post.ho,
                                  },
                                ];
                              // 제거
                              const copied = prev.slice();
                              copied.splice(selectedIdx, 1);
                              return copied;
                            });
                            return;
                          }

                          // 매물상세 조회
                          setSelectedPostId(post.id);
                        }}
                        style={{
                          padding: "20px 10px",
                          // marginBottom: "30px",
                          cursor: "pointer",
                          backgroundColor:
                            !!selectedTradeDonePosts.find(
                              (p) => p.dong === post.dong && p.ho === post.ho
                            ) || selectedPostId === post.id
                              ? "var(--tomato-light)"
                              : "#fff",
                        }}
                      >
                        {/* 윗줄 */}
                        <div
                          className="flex-row-between-center"
                          style={{ marginBottom: "4px" }}
                        >
                          <div className="flex-row-start-center">
                            <h2>
                              {post.dong}동 {post.ho}호
                            </h2>
                            <span
                              className="text-small"
                              style={{
                                color: "#fff",
                                padding: "4px 6px",
                                borderRadius: "8px",
                                backgroundColor: "var(--yellow-dark)",
                                marginLeft: "4px",
                              }}
                            >
                              {post.ownerName ? "양타" : "단타"}
                            </span>
                          </div>
                          <h2>{printCostPreferUk(post.costOnce)}</h2>
                        </div>

                        {/* 아랫줄 */}
                        <div className="flex-row-between-center">
                          <div>
                            <AreaTypeBox areaType={post.areaType} />
                            <p
                              className="text-small"
                              style={{ marginTop: "4px" }}
                            >
                              {post.exclusiveArea.toFixed(1).replace(".0", "")}
                              m²
                            </p>
                          </div>

                          <div
                            className="flex-row-start-center"
                            style={{ maxWidth: "300px" }}
                          >
                            <img
                              src={iconArrowDouble}
                              style={{
                                width: "24px",
                                height: "24px",
                                marginRight: "4px",
                              }}
                            />
                            {badgeWorth > 0 && (
                              <img
                                src={
                                  badgeWorth === 50
                                    ? iconMedalGold
                                    : badgeWorth === 30
                                    ? iconMedalSilver
                                    : iconMedalBronze
                                }
                                style={{ width: "32px", height: "32px" }}
                              />
                            )}
                            <p
                              className="text-medium"
                              style={{ color: "var(--brown)" }}
                            >
                              {creatorName}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {isSelectingTradeDone && (
                <div
                  className="flex-row-between-center"
                  style={{
                    padding: "10px",
                    gap: "10px",
                  }}
                >
                  <BaseButtonOutline
                    text="취소"
                    style={{ flex: 1 }}
                    onClick={() => setIsSelectingTradeDone(false)}
                  />
                  <BaseButton
                    text="거래완료요청"
                    style={{ flex: 1 }}
                    onClick={requestTradeDone}
                    disabled={selectedTradeDonePosts.length === 0}
                  />
                </div>
              )}

              {/* 닫기 버튼 */}
              <span
                style={{
                  padding: "4px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                }}
                onClick={() => setSelectedApartId("")}
              >
                <img
                  src={iconClose}
                  style={{ width: "28px", height: "28px" }}
                />
              </span>
            </div>

            {/* 매물상세 */}
            {!!postDetail && (
              <div
                style={
                  isMobile
                    ? {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: window.innerWidth,
                        marginLeft: 0,
                        borderRadius: 0,
                      }
                    : {}
                }
              >
                <PostDetail
                  post={postDetail}
                  wished={postDetail.wish}
                  withDetail
                  noBorderRadius={isMobile}
                  style={{ boxShadow: "none" }}
                  onClose={() => setSelectedPostId("")}
                  onClickCreator={({ postId, userId, type }) => {
                    r__setUserDetailModalInfo({
                      postId: postId,
                      userId: userId,
                      type: type,
                      apartId: postDetail.apartId,
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {/* 고객센터 팝업 + 플러팅 버튼 */}
      <div
        className="shadow"
        style={{
          position: "fixed",
          right: "36px",
          bottom: "36px",
          padding: "15px",
          borderRadius: "20px",
          backgroundColor: "var(--yellow-dark)",
          cursor: "pointer",
        }}
        onClick={() => {
          setVisibleContact((prev) => !prev);
        }}
      >
        <img src={iconHeadphone} style={{ width: "30px", height: "30px" }} />
      </div>
      {visibleContact && (
        <div
          className="shadow"
          style={{
            position: "absolute",
            right: isMobile ? "0" : "18px",
            top: isMobile ? "0" : "11px",
            width: isMobile ? "100%" : "400px",
            height: isMobile ? "100%" : undefined,
            backgroundColor: "#fff",
            borderRadius: isMobile ? "0" : "20px",
            padding: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CloseCircleButton
            style={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => {
              setVisibleContact(false);
            }}
          />

          <h1>꿀단지 고객센터</h1>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "var(--border-gray)",
              margin: "36px 0",
            }}
          >
            영업시간 : 월~금(공휴일 제외), 오전 10시 ~ 오후 05시 서비스이용불편
            접수, 배너광고 제휴, 환불 요청은 고객센터 전화문의 또는 카카오톡
            플러스 꿀단지플랫폼 핫라인 채팅문의를 통해 가능합니다.
          </p>
          <h1 style={{ color: "var(--brown)" }}>대표번호</h1>
          <h1 style={{ color: "var(--brown)" }}>1800-5664</h1>

          <button
            style={{
              backgroundColor: "#fff",
              border: "1px solid var(--yellow-dark)",
              borderRadius: "8px",
              width: "100%",
              height: "61px",
              color: "var(--beige)",
              fontSize: "20px",
              fontWeight: 400,

              marginTop: "36px",
            }}
            onClick={() => {
              // 채널톡 연결
              window.open(KAKAO_LINK, "_blank");
            }}
          >
            카카오톡 플러스채널 바로가기
          </button>
        </div>
      )}
    </div>
  );
};

export default MapPage;

import { forwardRef } from "react";

interface IProps {
  style?: React.CSSProperties;
  inputProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  buttonProps: {
    text: string;
    disabled?: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
  };
}

const InputWithButton = forwardRef(
  (props: IProps, ref: React.LegacyRef<HTMLInputElement>) => {
    return (
      <div
        className="flex-row-center-center"
        style={{
          width: "100%",
          height: "48px",
          border: "1px solid var(--border-gray)",
          borderRadius: "8px",
          ...(props.style ?? {}),
        }}
      >
        <input
          style={{ border: "none", flex: 1, height: "46px" }}
          {...props.inputProps}
          ref={ref}
        />

        <button
          disabled={props.buttonProps.disabled}
          onClick={props.buttonProps.onClick}
          style={{
            width: "107px",
            height: "32px",
            borderRadius: "8px",
            backgroundColor: "var(--beige)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.71px",
            color: "#fff",
            marginRight: "8px",
            ...(props.buttonProps.disabled
              ? {
                  backgroundColor: "var(--border-gray)",
                }
              : {}),

            ...(props.buttonProps.style ?? {}),
          }}
        >
          {props.buttonProps.text}
        </button>
      </div>
    );
  }
);

export default InputWithButton;
